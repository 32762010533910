import { NavLink } from 'react-router-dom'
import styled from 'styled-components'


export const Side = styled.aside`
  background-color: ${({ theme }) => theme.backgroundColor.white};
  border-right: 4px solid ${({ theme }) => theme.backgroundColor.light};
  width: 300px;
  padding: ${({ theme }) => theme.spacing.lg};
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  transition: width 0.3s ease;
  z-index: 10;

  @media (max-width: ${({ theme }) => theme.breakpoint.laptop}) {
    width: 80px;
    padding: ${({ theme }) => theme.spacing.sm};
  }

  @media (max-width: ${({ theme }) => theme.breakpoint.mobile}) {
    width: 60px;
    padding: ${({ theme }) => theme.spacing.xs};
  }
`

export const Header = styled.header`
  padding: ${({ theme }) => theme.spacing.md};
  display: flex;
  justify-content: start;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.breakpoint.laptop}) {
    justify-content: center;
  }
`

export const Logo = styled.img`
  width: 70px;
  height: auto;

  @media (max-width: ${({ theme }) => theme.breakpoint.mobile}) {
    width: 50px;
  }
`

export const Content = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding-bottom: ${({ theme }) => theme.spacing.md};
`

export const Items = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.md};
`

export const Item = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const SideLink = styled(NavLink)`
  color: ${({ theme }) => theme.textColor.secondary};
  font-family: ${({ theme }) => theme.font.primary};
  font-size: ${({ theme }) => theme.fontSize.xl};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  padding: ${({ theme }) => theme.spacing.md};
  border: 4px solid ${({ theme }) => theme.color.transparent};
  border-radius: ${({ theme }) => theme.borderRadius.xl};
  width: 100%;
  text-align: start;
  text-decoration: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &:hover {
    background-color: ${({ theme }) => theme.backgroundColor.light};
  }

  &.active {
    color: ${({ theme }) => theme.textColor.secondary};
    background-color: ${({ theme }) => theme.backgroundColor.light};
  }

  @media (max-width: ${({ theme }) => theme.breakpoint.laptop}) {
    justify-content: center;
  }
`

export const SideIcon = styled.div`
  font-size: ${({ theme }) => theme.fontSize.xxl};
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;

  @media (max-width: ${({ theme }) => theme.breakpoint.laptop}) {
    margin-right: 0;
  }
`

export const IconImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`

export const SideName = styled.span`
  display: inline;
  margin-left: ${({ theme }) => theme.spacing.md};

  @media (max-width: ${({ theme }) => theme.breakpoint.laptop}) {
    display: none;
  }
`

export const Divider = styled.hr`
  border: 0;
  border-top: 4px solid ${({ theme }) => theme.backgroundColor.light};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  margin: ${({ theme }) => theme.spacing.md} 0;
`
