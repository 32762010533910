import React from 'react'

import NavLink from './NavLink'

import { NavLinksContainer } from './styles'


const NavLinks = () => (
  <NavLinksContainer>
    <NavLink to="/">Home</NavLink>
    <NavLink to="/about">About</NavLink>
  </NavLinksContainer>
)

export default NavLinks
