export const theme = {
  color: {
    primary: '#A172FD',
    secondary: '#613DC1',
    success: '#25A244',
    warning: '#FCA311',
    danger: '#A4161A',
    transparent: 'transparent',
    white: 'white',
    black: 'black',
    accent: {
      100: '#D4CDF4',
      200: '#E0AAFF',
      300: '#C77DFF',
      400: '#9D4EDD',
      500: '#7B2CBF',
      600: '#5A189A',
      700: '#3C096C',
      800: '#240046',
      900: '#10002B',
    },

    grey: {
      100: '#f2f3f5',
      200: '#ebedef',
      300: '#e3e5e8',
      400: '#d4d7dc',
      500: '#72767d',
      600: '#4f545c',
      700: '#36393f',
      800: '#2f3136',
      900: '#202225',
    },

    blue: {
      100: '#BBDEFB',
      200: '#90CAF9',
      300: '#64B5F6',
      400: '#42A5F5',
      500: '#2196F3',
      600: '#1E88E5',
      700: '#1976D2',
      800: '#1565C0',
      900: '#0D47A1',
    },

    purple: {
      100: '#DAC3E8',
      200: '#D2B7E5',
      300: '#C19EE0',
      400: '#B185DB',
      500: '#A06CD5',
      600: '#9163CB',
      700: '#815AC0',
      800: '#7251B5',
      900: '#6247AA',
    },

    yellow: {
      100: '#FFF056',
      200: '#FFE94E',
      300: '#FFE246',
      400: '#FFDA3D',
      500: '#FFD53E',
      600: '#FECF3E',
      700: '#FDC43F',
      800: '#FDBE39',
      900: '#FDB833',
    },

    catalyst: {
      100: '#ffba08',
      200: '#faa307',
      300: '#f48c06',
      400: '#dc2f02',
    },

    vote: {
      commend: '#20BF55',
      commendLight: '#A5E0B5',
      neutral: '#CED4DA',
      neutralLight: '#E9ECEF',
      oppose: '#F02D3A',
      opposeLight: '#F57F85',
    },
  },

  backgroundColor: {
    primary: '#A172FD',
    secondary: '#613DC1',
    accent: '#d4cdf4',
    dark: '#242423',
    light: '#f2f3f5',
    space: '#03071E',
    white: 'white',
    black: 'black',
    success: '#008000',
    successLight: '#38b000',
    warning: '#FCA311',
    warningLight: '#FCA311',
    danger: '#DA344D',
    dangerLight: '#EC5766',
  },

  textColor: {
    primary: '#212529',
    secondary: '#6C757D',
    white: 'white',
    black: 'black',
    muted: '#ADB5BD',
    success: '#6EDE8A',
    warning: '#F5CB5C',
    danger: '#E5383B',
  },

  borderColor: {
    primary: '#A172FD',
    secondary: '#613DC1',
    accent: '#d4cdf4',
    dark: '#242423',
    light: '#f2f3f5',
    space: '#03071E',
    white: 'white',
    black: 'black',
    success: '#008000',
    successLight: '#38b000',
    warning: '#FCA311',
    warningLight: '#FCA311',
    danger: '#DA344D',
    dangerLight: '#EC5766',
  },

  font: {
    primary: "'Expletus Sans', sans-serif",
    secondary: "'Newsreader', serif",
  },

  fontSize: {
    base: '16px',
    xxs: '10px',
    xs: '12px',
    sm: '14px',
    md: '20px',
    lg: '24px',
    xl: '32px',
    xxl: '40px',
    xxxl: '50px',
    huge: '60px',
  },

  fontWeight: {
    light: 300,
    regular: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
  },

  spacing: {
    base: '12px',
    xxs: '2px',
    xs: '4px',
    sm: '8px',
    md: '16px',
    lg: '24px',
    xl: '32px',
    xxl: '48px',
    xxxl: '56px',
  },

  breakpoint: {
    mobile: '480px',
    tablet: '768px',
    laptop: '1024px',
    desktop: '1440px',
    desktopLG: '2560px',
  },

  borderRadius: {
    sm: '2px',
    md: '4px',
    lg: '8px',
    xl: '15px',
    pill: '50px',
    circle: '50%',
  },

  opacity: {
    none: '1',
    light: '0.5',
    medium: '0.75',
    strong: '0.9',
    subtle: '0.25',
  },

  shadows: {
    small: '0 1px 3px rgba(0, 0, 0, 0.12)',
    medium: '0 3px 6px rgba(0, 0, 0, 0.16)',
    large: '0 10px 20px rgba(0, 0, 0, 0.2)',
  },
}
