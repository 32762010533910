import React from 'react'

import SEO from '../../../../common/seo'

import { HeroSection, TermsSection } from './sect/index'


const Terms = () => {
  return (
    <>
    <SEO
      title="Terms of Service | Nebulei"
      description="Review Nebulei's Terms of Service to understand the guidelines and legal agreements for using our platform. Learn about user responsibilities, rights, and the terms that govern your experience on Nebulei."
      keywords="Nebulei, terms of service, user agreements, legal guidelines, platform usage, user rights, responsibilities, service terms, Nebulei terms"
    />
    <HeroSection />
    <TermsSection />
    </>
  )
}

export default Terms