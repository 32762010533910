import React from 'react'

import CommentItem from '../CommentItem'
import Load from '../Load'
import Button from '../Button'

import {
  List,
  Contain,
  Content,
  EmptyMessage,
} from './styles'


const CommentList = ({ comments = [], loading, emptyMessage, loadMore, hasMore }) => (
  <List>
    <Contain>
      <Content>
        {loading && !comments.length ? (
          <Load />
        ) : comments.length === 0 ? (
          <EmptyMessage>{emptyMessage || 'No comments yet.'}</EmptyMessage>
        ) : (
          <>
            {comments.map((comment) => (
              <CommentItem key={comment.id} comment={comment} />
            ))}
            {hasMore && (
              <Button
                type="button"
                text={loading ? 'Loading...' : 'Load More'}
                size="base"
                shape="rounded"
                onClick={loadMore}
              />
            )}
          </>
        )}
      </Content>
    </Contain>
  </List>
)

export default CommentList
