import React from 'react'
import { formatDistanceToNow } from 'date-fns'

import {
  Comment,
  AuthorWrapper,
  AuthorUser,
  AuthorLink,
  CommentContent,
  CommentText,
  CommentAgo
} from './styles'

const CommentItem = ({ comment }) => {
  return (
    <Comment $commenterVote={comment.vote_type}>
      <AuthorWrapper>
        <AuthorLink
          to={`/persona/${comment.commenter.username}`}
          onClick={(e) => e.stopPropagation()}
        >
          <AuthorUser>
            @{comment.commenter.username}
          </AuthorUser>
        </AuthorLink>
        <CommentAgo>
          {formatDistanceToNow(new Date(comment.created_at), { addSuffix: false })}
        </CommentAgo>
      </AuthorWrapper>
      <CommentContent>
        <CommentText>
          {comment.content}
        </CommentText>
      </CommentContent>
    </Comment>
  )
}

export default CommentItem
