import React from 'react'

import TagExperience from '../../../../../components/TagExperience'
import TagPoints from '../../../../../components/TagPoints'

import { TagGroup } from '../heroStyles'


const TagsSection = ({ persona, loading, error }) => (
  <TagGroup>
    <TagExperience persona={persona} loading={loading} error={error} />
    <TagPoints persona={persona} loading={loading} error={error} />
  </TagGroup>
)

export default TagsSection
