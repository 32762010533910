import React from 'react'
import { Link } from 'react-router-dom'
import { BaseButton, ButtonIcon } from './styles'


const Button = ({
  text,
  size = 'base',
  shape = 'rounded',
  bgColor,
  textColor,
  hoverColor,
  icon,
  iconRight = false,
  type = 'button',
  href,
  to,
  onClick,
  allowedRoles = [],
  ...rest
}) => {
  if (to && href) {
    console.error("Button should have either 'to' or 'href', not both.")
    return null
  }

  const conditionalOnClick = to || href ? undefined : onClick

  const buttonProps = {
    $size: size,
    $shape: shape,
    $bgColor: bgColor,
    $textColor: textColor,
    $hoverColor: hoverColor,
    $iconRight: iconRight,
  }

  const buttonContent = (
    <>
      {!iconRight && icon && <ButtonIcon>{icon}</ButtonIcon>}
      {text}
      {iconRight && icon && <ButtonIcon $iconRight>{icon}</ButtonIcon>}
    </>
  )

  if (to) {
    return (
      <BaseButton as={Link} to={to} {...buttonProps} {...rest}>
        {buttonContent}
      </BaseButton>
    )
  }

  if (href) {
    return (
      <BaseButton as="a" href={href} {...buttonProps} {...rest}>
        {buttonContent}
      </BaseButton>
    )
  }

  return (
    <BaseButton type={type} onClick={conditionalOnClick} {...buttonProps} {...rest}>
      {buttonContent}
    </BaseButton>
  )
}

export default Button
