import React from 'react'
import { Routes, Route } from 'react-router-dom'
import useScrollToTop from './hooks/useScrollToTop'
import GuestRoute from './routes/guestRoute'
import ProtectedRoute from './routes/protectedRoute' 

import { BaseLayout, SidebarLayout } from './components/Layouts'

import { Main, Auth, Settings, Menu, Profile, Publication } from './container/index'


function App() {
  useScrollToTop()

  return (
    <Routes>
      <Route
        path="/*"
        element={
          <BaseLayout>
            <GuestRoute>
              <Main />
            </GuestRoute>
          </BaseLayout>
        }
      />
      <Route
        path="auth/*"
        element={<Auth />}
      />

      <Route
        path="settings/*"
        element={
          <ProtectedRoute>
            <Settings />
          </ProtectedRoute>
        }
      />
      <Route
        path="menu/"
        element={
          <SidebarLayout>
            <ProtectedRoute>
              <Menu />
            </ProtectedRoute>
          </SidebarLayout>
        }
      />
      <Route
        path="persona/:slug/"
        element={
          <SidebarLayout>
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          </SidebarLayout >
        }
      />
      <Route
        path="publication/*"
        element={
          <ProtectedRoute>
            <Publication />
          </ProtectedRoute>
        }
      />
    </Routes>
  )
}

export default App
