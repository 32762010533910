import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import SEO from '../../../../common/seo'
import { useAuth } from '../../../../hooks/useAuth'

import Form from '../../../../components/Form/index'
import Message from '../../../../components/Message/index'

import {
  Section,
  VerifyBox,
  Header,
  EmailInfo,
  NoResend,
  ResendLink,
  BackArrow,
} from './styles'


const Verify = () => {
  const { user, verify, resendVerificationCode, logout, validated } = useAuth()
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [resendTimer, setResendTimer] = useState(0)
  const navigate = useNavigate()

  const RESEND_TIMEOUT = 60
  const RESEND_STORAGE_KEY = 'resendTimerExpiry'

  useEffect(() => {
    const storedExpiry = localStorage.getItem(RESEND_STORAGE_KEY)
    if (storedExpiry) {
      const expiryTime = parseInt(storedExpiry, 10)
      const currentTime = Date.now()
      const remainingTime = expiryTime - currentTime
      if (remainingTime > 0) {
        setResendTimer(Math.ceil(remainingTime / 1000))
      } else {
        localStorage.removeItem(RESEND_STORAGE_KEY)
      }
    }
  }, [])

  useEffect(() => {
    if (resendTimer <= 0) return

    const interval = setInterval(() => {
      setResendTimer((prevTimer) => {
        if (prevTimer <= 1) {
          clearInterval(interval)
          localStorage.removeItem(RESEND_STORAGE_KEY)
          return 0
        }
        return prevTimer - 1
      })
    }, 1000)

    return () => clearInterval(interval)
  }, [resendTimer])

  const handleVerifySubmit = async (formData) => {
    setIsLoading(true)
    setErrorMessage('')
    setSuccessMessage('')

    try {
      await verify(formData.verification_code)
      setSuccessMessage('Verification successful.')
      navigate('/menu')
    } catch (error) {
      setErrorMessage('Verification failed. Please check the code and try again.')
    } finally {
      setIsLoading(false)
    }
  }

  const handleResendCode = async () => {
    if (resendTimer > 0) {
      return
    }

    setIsLoading(true)
    setErrorMessage('')
    setSuccessMessage('')

    if (validated) {
      setErrorMessage('User is already verified')
      setIsLoading(false)
      return
    }

    try {
      await resendVerificationCode()
      setSuccessMessage('A new verification code has been sent to your email.')
      setResendTimer(RESEND_TIMEOUT)
      const newExpiry = Date.now() + RESEND_TIMEOUT * 1000
      localStorage.setItem(RESEND_STORAGE_KEY, newExpiry.toString())
    } catch (error) {
      setErrorMessage('Failed to resend verification code. Please try again later.')
    } finally {
      setIsLoading(false)
    }
  }

  const handleCancel = () => {
    logout()
  }

  const maskEmail = (email) => {
    if (!email) return ''
    const [localPart, domain] = email.split('@')
    const maskedLocal = localPart.length > 2 ? localPart.slice(0, 2) + '***' : localPart + '***'
    return `${maskedLocal}@${domain}`
  }

  const handleKeyDown = useCallback((event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
    }
  }, [])

  const fields = useMemo(() => [
    {
      name: 'verification_code',
      type: 'text',
      placeholder: 'Enter your verification code',
      label: 'Verification Code',
      onKeyDown: handleKeyDown,
    },
  ], [handleKeyDown])

  return (
    <>
      <SEO
        title="Verify Your Nebulei Account | Complete Your Registration"
        description="Enter your verification code to complete your Nebulei account registration and gain full access to our professional community. Haven't received the code? Resend it easily."
        keywords="Nebulei account verification, verify Nebulei account, complete registration, resend verification code, Nebulei verify code"
      />
      <Section>
        <VerifyBox>
          <BackArrow onClick={handleCancel} disabled={isLoading} />

          <Header>Verify Your Account</Header>

          {user && user.email && (
            <EmailInfo>
              A verification code has been sent to: <strong>{maskEmail(user.email)}</strong>
            </EmailInfo>
          )}

          {errorMessage && <Message type="error" message={errorMessage} />}
          {successMessage && <Message type="success" message={successMessage} />}

          <Form
            fields={fields}
            onSubmit={handleVerifySubmit}
            isLoading={isLoading}
            buttonText="Verify"
          />

          <NoResend>
            Didn't receive the code?{' '}
            <ResendLink onClick={handleResendCode} disabled={isLoading || resendTimer > 0}>
              {resendTimer > 0 ? `Resend in ${resendTimer}s` : 'Resend'}
            </ResendLink>
          </NoResend>
        </VerifyBox>
      </Section>
    </>
  )
}

export default Verify
