import React from 'react'

import Brand from './NavBrand'
import NavLinks from './NavLinks'

import { Nav, Contain } from './styles'


const Navbar = () => {
  return (
    <Nav>
      <Contain>
        <Brand />
        <NavLinks />
      </Contain>
    </Nav>
  )
}

export default Navbar
