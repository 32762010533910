import React, { useState, useEffect } from 'react'

import Input from '../Input'
import Select from '../Select'
import TextArea from '../TextArea'
import Options from '../Options'
import Button from '../Button'

import { Form as StyledForm, FieldContainer, Label } from './styles'

const DynamicForm = ({ fields, onSubmit, isLoading, buttonText, buttonColor, hoverColor }) => {
  const [formData, setFormData] = useState({})
  const [initialData, setInitialData] = useState({})
  const [isChanged, setIsChanged] = useState(false)
  const [passwordVisibility, setPasswordVisibility] = useState({})

  useEffect(() => {
    const initialFormData = {}
    fields.forEach((field) => {
      initialFormData[field.name] = field.value || ''
    })
    setFormData(initialFormData)
    setInitialData(initialFormData)
  }, [fields])

  useEffect(() => {
    setIsChanged(JSON.stringify(formData) !== JSON.stringify(initialData))
  }, [formData, initialData])

  const handleChange = (name, value) => {
    setFormData((prev) => ({ ...prev, [name]: value }))
  }

  const togglePasswordVisibility = (fieldName) => {
    setPasswordVisibility((prev) => ({
      ...prev,
      [fieldName]: !prev[fieldName]
    }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    await onSubmit(formData)
  }

  return (
    <StyledForm onSubmit={handleSubmit}>
      {fields.map((field) => (
        <FieldContainer key={field.name}>
          {field.title && <Label>{field.title}</Label>}
          {field.type === 'select' ? (
            <Select
              name={field.name}
              value={formData[field.name]}
              onChange={(e) => handleChange(field.name, e.target.value)}
              options={field.options}
            />
          ) : field.type === 'textarea' ? (
            <TextArea
              name={field.name}
              value={formData[field.name]}
              onChange={(e) => handleChange(field.name, e.target.value)}
              placeholder={field.placeholder}
            />
          ) : field.type === 'options' ? (
            <Options
              options={field.options}
              selectedOption={formData[field.name]}
              onOptionClick={(value) => handleChange(field.name, value)}
              selectedColor={field.selectedColor}
              defaultColor={field.defaultColor}
              hoverColor={field.hoverColor}
            />
          ) : (
            <Input
              type={field.type}
              name={field.name}
              value={formData[field.name]}
              onChange={(e) => handleChange(field.name, e.target.value)}
              placeholder={field.placeholder}
              togglePasswordVisibility={togglePasswordVisibility}
              isPasswordVisible={passwordVisibility[field.name] || false}
              onKeyDown={field.onKeyDown}
            />
          )}
        </FieldContainer>
      ))}
      <Button
        type="submit"
        text={isLoading ? 'Loading...' : buttonText}
        size="base"
        shape="rounded"
        bgColor={buttonColor}
        hoverColor={hoverColor}
        disabled={isLoading || !isChanged}
      />
    </StyledForm>
  )
}

export default DynamicForm
