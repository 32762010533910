import React from 'react'

import { ReactTyped as Typed } from 'react-typed'
import Button from '../../../../../../components/Button/index'

import {
  Section,
  Contain,
  Content,
  Header,
  TypedText,
  EarthText,
  BtnContain
} from './heroStyles'


const Hero = () => {
  return (
    <Section>
      <Contain>
        <Content>
          <Header>
            Empowering
            <br />
            <TypedText>
              <Typed
                strings={['Innovators', 'Creators', 'Researchers', 'Visionaries', 'Pioneers']}
                typeSpeed={50}
                backSpeed={30}
                loop
              />
            </TypedText>
            <br />
            of{' '}<EarthText>Earth</EarthText>.
          </Header>
          <BtnContain>
            <Button
              text="Join Nebulei"
              size="lg"
              shape="pill"
              type="submit"
              to="/auth/join"
            />
          </BtnContain>
        </Content>
      </Contain>
    </Section>
  )
}

export default Hero
