import { React, useState, useRef, useEffect } from 'react'
import ReactDOM from 'react-dom'

import { DropOutWrapper, Trigger, DropOutContent, DropOutContainer } from './styles'


const DropOut = ({ children, trigger, direction = 'down', className = '' }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [position, setPosition] = useState({})
  const triggerRef = useRef(null)
  const contentRef = useRef(null)
  const closeTimeoutRef = useRef(null)

  useEffect(() => {
    if (isOpen && triggerRef.current && contentRef.current) {
      const triggerRect = triggerRef.current.getBoundingClientRect()
      const contentRect = contentRef.current.getBoundingClientRect()
      const calculatedPosition = getDropdownPosition(triggerRect, contentRect, direction)
      setPosition(calculatedPosition)
    }
  }, [isOpen, direction])

  const getDropdownPosition = (triggerRect, contentRect, direction) => {
    const horizontalCenter = triggerRect.left + (triggerRect.width - contentRect.width) / 2
    const verticalCenter = triggerRect.top + (triggerRect.height - contentRect.height) / 2

    switch (direction) {
      case 'up':
        return { top: triggerRect.top - contentRect.height, left: horizontalCenter }
      case 'down':
        return { top: triggerRect.bottom, left: horizontalCenter }
      case 'left':
        return { top: verticalCenter, left: triggerRect.left - contentRect.width }
      case 'right':
        return { top: verticalCenter, left: triggerRect.right }
      case 'center':
      default:
        return { top: verticalCenter, left: horizontalCenter }
    }
  }

  const handleMouseEnter = () => {
    if (closeTimeoutRef.current) clearTimeout(closeTimeoutRef.current)
    setIsOpen(true)
  }

  const handleMouseLeave = () => {
    closeTimeoutRef.current = setTimeout(() => setIsOpen(false), 600)
  }

  const dropdownContent = (
    <DropOutContainer
      ref={contentRef}
      style={{ ...position }}
      $isOpen={isOpen}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <DropOutContent>{children}</DropOutContent>
    </DropOutContainer>
  )

  return (
    <DropOutWrapper ref={triggerRef} className={className}>
      <Trigger onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        {trigger}
      </Trigger>

      {isOpen && ReactDOM.createPortal(dropdownContent, document.body)}
    </DropOutWrapper>
  )
}

export default DropOut
