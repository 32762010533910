import React from 'react'
import { Routes, Route } from 'react-router-dom'

import { SidebarLayout } from '../../components/Layouts'
import { Account, Profile, Logout } from './views'
import NotFound from '../NotFound'

import { GoHomeFill } from 'react-icons/go'
import { RiProfileFill } from 'react-icons/ri'
import { BsFillPersonFill } from 'react-icons/bs'
import { TbLogout } from 'react-icons/tb'


const settingLinks = [
  { to: '/menu', label: 'Menu', icon: <GoHomeFill /> },
  { divider: true },
  { to: '/settings/profile', label: 'Profile', icon: <BsFillPersonFill /> },
  { to: '/settings/account', label: 'Account', icon: <RiProfileFill /> },
  { to: '/settings/logout', label: 'Logout', icon: <TbLogout /> },
]

const Settings = () => {
  return (
    <Routes>

      <Route path="account" element={<SidebarLayout links={settingLinks}><Account /></SidebarLayout>} />
      <Route path="profile" element={<SidebarLayout links={settingLinks}><Profile /></SidebarLayout>} />
      <Route path="logout" element={<SidebarLayout links={settingLinks}><Logout /></SidebarLayout>} />

      <Route path="*" element={<NotFound />} />

    </Routes>
  )
}

export default Settings
