import styled from 'styled-components'


export const Select = styled.select`
  color: ${({ theme }) => theme.textColor.primary};
  font-family: ${({ theme }) => theme.font.primary};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${({ theme }) => theme.fontSize.md};
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  border: 2px solid ${({ theme }) => theme.backgroundColor.light};
  padding: ${({ theme }) => theme.spacing.base};
  margin-bottom: ${({ theme }) => theme.spacing.base};
  width: 100%;
  padding-right: 40px;

  &:focus {
    outline: none;
  }
`