import React from 'react'

import { LoaderContainer, Loader } from './styles'


const Loading = () => {
  return (
    <LoaderContainer>
      <Loader />
    </LoaderContainer>
  )
}

export default Loading