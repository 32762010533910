import React from 'react'
import AuthContext from './authContexts'
import { useProvideAuth } from '../hooks/useProvideAuth'

import Loading from '../components/Loading/index'


export const AuthProvider = ({ children }) => {
  const auth = useProvideAuth()

  if (auth.loading) {
    return <Loading />
  }

  return (
    <AuthContext.Provider value={auth}>
      {children}
    </AuthContext.Provider>
  )
}
