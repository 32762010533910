import { useQuery } from 'react-query'
import { useAuth } from '../useAuth'
import { retrievePublicationService } from '../../services/publicationService'


export const useRetrievePublication = (publicationId) => {
  const { token } = useAuth()

  const { data, isLoading, error } = useQuery(
    ['publication', publicationId],
    () => retrievePublicationService(token, publicationId),
    {
      enabled: !!publicationId && !!token,
      staleTime: 5 * 60 * 1000,
      cacheTime: 10 * 60 * 1000,
    }
  )

  return { publication: data, loading: isLoading, error }
}
