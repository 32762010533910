import { useQuery, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../hooks/useAuth'
import {
  getMyAccountService,
  deleteMyAccountService,
  updateMyAccountService,
  changePasswordService,
} from '../services/accountService'


const CACHE_TIME = 10 * 60 * 1000
const STALE_TIME = 5 * 60 * 1000

export const useAccount = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { token, logout } = useAuth()

  const { data: account, isLoading, error, refetch } = useQuery(
    ['account'],
    () => getMyAccountService(token),
    {
      enabled: !!token,
      staleTime: STALE_TIME,
      cacheTime: CACHE_TIME,
    }
  )

  const updateAccount = async (updatedData, isPartial = false) => {
    await updateMyAccountService(token, updatedData, isPartial)
    refetch()
  }

  const deleteAccount = async (password) => {
    try {
      await deleteMyAccountService(token, { password })
      queryClient.removeQueries(['account'])
      logout()
      navigate('/')
    } catch (error) {
      console.error('Account deletion failed:', error)
      throw error
    }
  }

  const changePassword = async (passwordData) => {
    await changePasswordService(token, passwordData)
  }

  return {
    account,
    isLoading,
    error,
    updateAccount,
    deleteAccount,
    changePassword,
  }
}
