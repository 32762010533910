import React from 'react'
import { Tag, TagIcon, TagContent, TagLabel } from './styles'
import { points as pointsIcon } from '../../assets'


const TagPoints = ({ persona, loading, error }) => {
  if (loading || error) {
    return null
  }

  return (
    <Tag>
      <TagIcon src={pointsIcon} alt="Points Icon" />
      <TagContent>
        <TagLabel>{persona?.points}</TagLabel>
      </TagContent>
    </Tag>
  )
}

export default TagPoints
