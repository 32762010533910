import React from 'react'
import { 
  Section,
  Contain,
  Content,
  PublicationDate,
  PublicationType,
  TypeText
} from './headerStyles'


const HeaderSection = ({ publication }) => {
  const formattedDate = publication?.created_at
    ? new Date(publication.created_at).toLocaleDateString("en-US", {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })
    : 'Unknown Date'

  return (
    <Section>
      <Contain>
        <Content>
          <PublicationDate>{formattedDate}</PublicationDate>
          <PublicationType>
            <TypeText>{publication?.type || 'Unknown Type'}</TypeText>
          </PublicationType>
        </Content>
      </Contain>
    </Section>
  )
}

export default HeaderSection
