import React from 'react'

import { Contain, Area } from './styles'


const TextArea = ({
  name,
  placeholder = '',
  value = '',
  onChange,
  bgColor = 'transparent',
  textColor = 'primary',
  borderColor = 'light',
  minHeight = '20vh',
  placeholderColor = 'white',
  fontSize,
  borderRadius,
  padding,
  marginBottom,
  lineHeight,
  placeholderFontSize,
}) => {
  return (
    <Contain>
      <Area
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        $bgColor={bgColor}
        $textColor={textColor}
        $borderColor={borderColor}
        $minHeight={minHeight}
        $fontSize={fontSize}
        $borderRadius={borderRadius}
        $padding={padding}
        $marginBottom={marginBottom}
        $lineHeight={lineHeight}
        $placeholderColor={placeholderColor}
        $placeholderFontSize={placeholderFontSize}
      />
    </Contain>
  )
}

export default TextArea
