import React from 'react'

import Load from '../Load'
import Message from '../Message'

import { Card, Contain, Name, Footer, Username } from './styles'


const ProfileCard = ({ account, persona, loading, error }) => {
  return (
    <Card $cardColor={persona?.color}>
      {loading ? (
        <Load />
      ) : error ? (
        <Message>Error loading account or persona.</Message>
      ) : (
        <Contain>
          <Name>{persona?.display_name}</Name>
          <Footer>
            <Username>@{account?.username}</Username>
          </Footer>
        </Contain>
      )}
    </Card>
  )
}

export default ProfileCard
