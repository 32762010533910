import { useState, useMemo, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import {
  loginService,
  registerService,
  verifyService,
  validateService,
  logoutService,
  resendVerificationCodeService,
  requestPasswordResetService,
  confirmPasswordResetService,
} from '../services/authService'


export const useProvideAuth = () => {
  const [user, setUser] = useState(null)
  const [token, setToken] = useState(localStorage.getItem('token') || null)
  const [validated, setValidated] = useState(null)
  const [authError, setAuthError] = useState(null)
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const { isLoading: loading } = useQuery(
    ['validateUser', token],
    () => validateService(token),
    {
      enabled: !!token && validated === null,
      retry: false,
      onSuccess: (validatedUser) => {
        setUser(validatedUser)
        setValidated(validatedUser.is_verified)
        setAuthError(null)
      },
      onError: (error) => {
        setAuthError(error)
        setValidated(false)
        setUser(null)
        setToken(null)
        localStorage.removeItem('token')
        navigate('/auth/login')
      },
    }
  )

  const logoutMutation = useMutation(() => logoutService(token), {
    onMutate: async () => {
      setUser(null)
      setToken(null)
      setValidated(false)
      localStorage.removeItem('token')
      await queryClient.clear()
    },
    onSuccess: () => navigate('/'),
  })

  const loginMutation = useMutation(({ email, password }) => loginService(email, password), {
    onSuccess: (data) => {
      localStorage.setItem('token', data.token)
      setToken(data.token)
      setUser(data.user)
      setValidated(data.user.is_verified)

      if (data.user.is_verified) {
        navigate('/menu')
      } else {
        navigate('/auth/verify')
      }
    },
  })

  const registerMutation = useMutation((formData) => registerService(formData), {
    onSuccess: (data) => {
      localStorage.setItem('token', data.token)
      setToken(data.token)
      setUser(data.user)
      setValidated(data.user.is_verified)

      if (data.user.is_verified) {
        navigate('/menu')
      } else {
        navigate('/auth/verify')
      }
    },
  })

  const verifyMutation = useMutation((verificationCode) => verifyService(verificationCode, token), {
    onSuccess: () => {
      setUser((prevUser) => ({ ...prevUser, is_verified: true }))
      setValidated(true)
      navigate('/menu')
    },
  })

  const resendVerificationMutation = useMutation(() => resendVerificationCodeService(token), {
    onError: (error) => {
      console.error('Resend Verification Error:', error)
    },
  })

  const passwordResetRequestMutation = useMutation((email) => requestPasswordResetService(email), {
    onError: (error) => {
      console.error('Password Reset Request Error:', error)
    },
  })

  const passwordResetConfirmMutation = useMutation(
    ({ resetToken, newPassword }) => confirmPasswordResetService(resetToken, newPassword),
    {
      onError: (error) => {
        console.error('Password Reset Confirm Error:', error)
      },
    }
  )

  const login = useCallback(async (email, password) => {
    await loginMutation.mutateAsync({ email, password })
  }, [loginMutation])

  const register = useCallback(async (formData) => {
    await registerMutation.mutateAsync(formData)
  }, [registerMutation])

  const verify = useCallback(async (verificationCode) => {
    await verifyMutation.mutateAsync(verificationCode)
  }, [verifyMutation])

  const resendVerificationCode = useCallback(async () => {
    await resendVerificationMutation.mutateAsync()
  }, [resendVerificationMutation])

  const requestPasswordReset = useCallback(async (email) => {
    await passwordResetRequestMutation.mutateAsync(email)
  }, [passwordResetRequestMutation])

  const confirmPasswordReset = useCallback(async (resetToken, newPassword) => {
    await passwordResetConfirmMutation.mutateAsync({ resetToken, newPassword })
  }, [passwordResetConfirmMutation])

  const logout = useCallback(() => {
    logoutMutation.mutate()
  }, [logoutMutation])



  const auth = useMemo(
    () => ({
      user,
      token,
      loading,
      validated,
      authError,
      login,
      register,
      verify,
      logout,
      resendVerificationCode,
      requestPasswordReset,
      confirmPasswordReset,
    }),
    [
      user,
      token,
      loading,
      validated,
      authError,
      login,
      register,
      verify,
      logout,
      resendVerificationCode,
      requestPasswordReset,
      confirmPasswordReset,
    ]
  )

  return auth
}
