import React from 'react'
import PropTypes from 'prop-types'
import {
  Side,
  Header,
  Logo,
  Content,
  Items,
  Item,
  Divider,
  SideLink,
  SideIcon,
  SideName,
  IconImage
} from './styles'

const Sidebar = ({ logoSrc, links = [] }) => {
  return (
    <Side>
      <Header>
        <Logo src={logoSrc} alt="Logo" />
      </Header>
      <Content>
        <Items>
          {links.map((link, index) => (
            <React.Fragment key={index}>
              {link.divider && <Divider />}
              {!link.divider && (
                <Item>
                  <SideLink to={link.to}>
                    {link.icon && (
                      <SideIcon>
                        {typeof link.icon === 'string' ? (
                          <IconImage src={link.icon} alt={`${link.label} Icon`} />
                        ) : (
                          link.icon
                        )}
                      </SideIcon>
                    )}
                    <SideName>{link.label}</SideName>
                  </SideLink>
                </Item>
              )}
            </React.Fragment>
          ))}
        </Items>
      </Content>
    </Side>
  )
}

Sidebar.propTypes = {
  logoSrc: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        to: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        icon: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.element
        ]),
      }),
      PropTypes.shape({
        divider: PropTypes.bool.isRequired,
      }),
    ])
  ).isRequired,
}

export default Sidebar
