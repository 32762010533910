import React from 'react'
import { Navigate } from 'react-router-dom'
import { useAuth } from '../hooks/useAuth'

import Loading from '../components/Loading'


const ProtectedRoute = ({ children, requiredRole = null, requireVerified = true, redirectPath = '/' }) => {
  const { token, loading, validated, authError } = useAuth()

  if (loading || validated === null) return <Loading />

  if (authError || !token) return <Navigate to="/login" />

  if (requireVerified && !validated) return <Navigate to="/auth/verify" />

  return children
}

export default ProtectedRoute
