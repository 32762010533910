import React from 'react'
import Form from '../../../../../components/Form'
import Message from '../../../../../components/Message/Message'


const AccountEditForm = ({ account, loading, error, success, onSubmit }) => {
  const accountFields = [
    { name: 'email', title: 'Email', value: account?.email ?? '', type: 'email' },
    { name: 'username', title: 'Username', value: account?.username ?? '', type: 'text' },
    { name: 'first_name', title: 'First Name', value: account?.first_name ?? '', type: 'text' },
    { name: 'last_name', title: 'Last Name', value: account?.last_name ?? '', type: 'text' },
    { name: 'password', title: 'Current Password', placeholder: 'input password to update account', type: 'password' },
  ]

  return (
    <>
      {error && <Message type="error" message={error} />}
      {success && <Message type="success" message={success} />}
      <Form
      fields={accountFields}
      onSubmit={onSubmit}
      isLoading={loading}
      buttonText="Update Account"
      />
    </>
  )
}

export default AccountEditForm
