import styled from 'styled-components'


export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const FieldContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.xl};
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoint.tablet}) {
    margin-bottom: ${({ theme }) => theme.spacing.lg};
  }

  @media (max-width: ${({ theme }) => theme.breakpoint.mobile}) {
    margin-bottom: ${({ theme }) => theme.spacing.md};
  }
`

export const Label = styled.label`
  color: ${({ theme }) => theme.textColor.secondary};
  font-family: ${({ theme }) => theme.font.primary};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${({ theme }) => theme.fontSize.base};
  margin-bottom: ${({ theme }) => theme.spacing.sm};
  display: block;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoint.tablet}) {
    font-size: ${({ theme }) => theme.fontSize.sm};
  }

  @media (max-width: ${({ theme }) => theme.breakpoint.mobile}) {
    font-size: ${({ theme }) => theme.fontSize.xs};
  }
`