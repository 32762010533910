import { React, useState } from 'react'

import { 
  Section,
  Contain,
  LogoWrapper,
  Logo,
  Content,
  HeaderWrapper,
  Heading,
  StatusLabel,
  Description,
} from './featuresStyles'
import { publication, catalyst, guild, duel } from '../../../../../../assets'


export const features = [
  {
    name: 'Publication',
    description: 'Allows you to publish an idea, proposal, or theory. This feature serves as the foundation for initiating other features.',
    logo: publication,
    status: ''
  },
  {
    name: 'Catalyst',
    description: 'A collaboration tool that enables users to accelerate innovation and solve problems by working together on various challenges.',
    logo: catalyst,
    status: 'In Development'
  },
  {
    name: 'Guild',
    description: 'A community space where users can form alliances to collaborate on shared goals and projects.',
    logo: guild,
    status: 'Coming Soon'
  },
  {
    name: 'Duel',
    description: 'A competitive feature where users challenge each other in skill-based engagements to showcase their prowess, refine their skills, and contribute to the broader knowledge base in their area of expertise.',
    logo: duel,
    status: 'Coming Soon'
  }
]


const Features = () => {
  const [loadedImages, setLoadedImages] = useState([])

  const handleImageLoad = (index) => {
    setLoadedImages((prev) => [...prev, index])
  }

  return (
    <Section>
      {features.map((feature, index) => (
        <Contain key={feature.name} $reverse={index % 2 !== 0}>
          <LogoWrapper $reverse={index % 2 !== 0}>
            <Logo
              src={feature.logo}
              alt={feature.name}
              className={loadedImages.includes(index) ? 'loaded' : ''}
              onLoad={() => handleImageLoad(index)}
            />
          </LogoWrapper>
          <Content $reverse={index % 2 !== 0}>
            <HeaderWrapper>
              <Heading>
                {feature.name}
              </Heading>
              <StatusLabel>
                {feature.status}
              </StatusLabel>
            </HeaderWrapper>
            <Description>{feature.description}</Description>
          </Content>
        </Contain>
      ))}
    </Section>
  )
}

export default Features
