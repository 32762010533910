import { React, useState } from 'react'

import SEO from '../../../../common/seo'
import { useAuth } from '../../../../hooks/useAuth'

import Button from '../../../../components/Button'
import Message from '../../../../components/Message'

import { Section, Contain, Content, Header } from './styles'


const Logout = () => {
  const { logout } = useAuth()
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const handleLogout = async () => {
    setIsLoading(true)
    setErrorMessage('')
    try {
      await logout()
    } catch (error) {
      console.error('Logout failed:', error)
      setErrorMessage('Failed to logout. Please try again.')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <SEO
        title="Logout? | Nebulei"
        description="You are about to log out of Nebulei. Ensure your account remains secure and easily accessible for your next session in Nebulei's professional community."
        keywords="Nebulei logout, secure account, log out Nebulei, Nebulei sign out, account security, professional community"
      />
      <Section>
        <Contain>
          <Content>
            <Header>Logout from Nebulei?</Header>

            {errorMessage && <Message type="error" message={errorMessage} />}

            <Button
              text={isLoading ? 'Logging out...' : 'Logout'}
              bgColor="red"
              textColor="white"
              onClick={handleLogout}
              disabled={isLoading}
              size="lg"
              shape="rounded"
              aria-label="Logout from Nebulei"
            />
          </Content>
        </Contain>
      </Section>
    </>
  )
}

export default Logout
