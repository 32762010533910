import React from 'react'

import ProfileCard from '../../../../../components/ProfileCard'

import { ProfileWrapper } from '../heroStyles'


const ProfileSection = ({ account, persona, loading, error }) => (
  <ProfileWrapper>
    <ProfileCard account={account} persona={persona} loading={loading} error={error} />
  </ProfileWrapper>
)

export default ProfileSection
