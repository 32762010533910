import axios from 'axios'
import {
  createPublicationUrl,
  listPublicationsUrl,
  listAuthorPublicationsUrl,
  retrievePublicationUrl,
  deletePublicationUrl,
  commentCreateUrl,
  commentListUrl,
  commentRetrieveUrl,
  commentDeleteUrl,
  voteCreateUrl,
  voteCountUrl,
} from './urls'


const getAuthHeader = (token) => ({
  Authorization: `Token ${token}`,
})

const request = async (method, url, token = null, data = {}) => {
  try {
    const config = token ? { headers: getAuthHeader(token) } : {}
    const response = await axios({ method, url, data, ...config })
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const handleError = (error) => {
  if (error.response) {
    console.error('API Error:', error.response.data)
    throw error.response.data
  } else {
    console.error('Network Error:', error.message)
    throw error
  }
}


export const createPublicationService = (token, formData) => request('post', createPublicationUrl, token, formData)


export const listPublicationsService = async (token, page = 1, pageSize = 20) => {
  const url = `${listPublicationsUrl}?page=${page}&pageSize=${pageSize}`

  try {
    const config = token ? { headers: getAuthHeader(token) } : {}
    const response = await axios.get(url, config)
    const { results, count, next, previous } = response.data

    return {
      results,
      count,
      next,
      previous,
      pageSize,
    }
  } catch (error) {
    return handleError(error)
  }
}

export const listAuthorPublicationsService = async (
  token,
  slug,
  page = 1,
  pageSize = 20
) => {
  const url = listAuthorPublicationsUrl(slug) + `?page=${page}&pageSize=${pageSize}`

  try {
    const config = token ? { headers: getAuthHeader(token) } : {}
    const response = await axios.get(url, config)
    const { results, count, next, previous } = response.data

    return {
      results,
      count,
      next,
      previous,
      pageSize,
    }
  } catch (error) {
    return handleError(error)
  }
}

export const retrievePublicationService = (token, publicationId) => request('get', retrievePublicationUrl(publicationId), token)
export const deletePublicationService = (token, publicationId) => request('delete', deletePublicationUrl(publicationId), token)

export const createCommentService = (token, publicationId, commentData) => request('post', commentCreateUrl(publicationId), token, commentData)

export const listCommentsService = async (token, publicationId, page = 1, pageSize = 20) => {
  const url = `${commentListUrl(publicationId)}?page=${page}&pageSize=${pageSize}`

  try {
    const config = token ? { headers: getAuthHeader(token) } : {}
    const response = await axios.get(url, config)
    const { results, count, next, previous } = response.data

    return {
      results,
      count,
      next,
      previous,
      pageSize,
    }
  } catch (error) {
    return handleError(error)
  }
}

export const retrieveCommentService = (token, publicationId, commentId) => request('get', commentRetrieveUrl(publicationId, commentId), token)
export const deleteCommentService = (token, publicationId, commentId) => request('delete', commentDeleteUrl(publicationId, commentId), token)

export const createVoteService = (token, publicationId, voteType) => request('post', voteCreateUrl(publicationId), token, { vote_type: voteType })
export const getVoteCountService = (token, publicationId) => request('get', voteCountUrl(publicationId), token)
