import styled from 'styled-components'
import { Link } from 'react-router-dom'


export const Section = styled.section`
  background-color: ${({ theme }) => theme.backgroundColor.accent};
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoint.mobile}) {
    flex-direction: column;
  }
`

export const Contain = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 500px;

  @media (max-width: ${({ theme }) => theme.breakpoint.mobile}) {
    max-width: 90%;
  }
`

export const LogoLink = styled(Link)`
  display: inline-block;
  margin-bottom: ${({ theme }) => theme.spacing.lg};

  @media (max-width: ${({ theme }) => theme.breakpoint.mobile}) {
    margin-bottom: ${({ theme }) => theme.spacing.md};
  }
`

export const Logo = styled.img`
  width: 100px;
  height: auto;

  @media (max-width: ${({ theme }) => theme.breakpoint.mobile}) {
    width: 80px;
  }
`

export const LoginBox = styled.div`
  width: 100%;
  max-width: 400px;
  padding: ${({ theme }) => theme.spacing.xxl};
  background-color: ${({ theme }) => theme.backgroundColor.white};
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  border: 4px solid ${({ theme }) => theme.backgroundColor.light};
  box-shadow: ${({ theme }) => theme.shadows.medium};
  text-align: center;
  overflow: auto;

  @media (max-width: ${({ theme }) => theme.breakpoint.mobile}) {
    padding: ${({ theme }) => theme.spacing.md};
    max-width: 90%;
    border-width: 2px;
    max-height: 90vh;
  }
`

export const Header = styled.h1`
  color: ${({ theme }) => theme.textColor.primary};
  font-family: ${({ theme }) => theme.font.primary};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.fontSize.xl};
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacing.xxl};

  @media (max-width: ${({ theme }) => theme.breakpoint.mobile}) {
    font-size: ${({ theme }) => theme.fontSize.lg};
    margin-bottom: ${({ theme }) => theme.spacing.md};
  }
`

export const Forgot = styled(Link)`
  color: ${({ theme }) => theme.textColor.secondary};
  font-family: ${({ theme }) => theme.font.primary};
  font-weight: ${({ theme }) => theme.fontWeight.light};
  font-size: ${({ theme }) => theme.fontSize.xs};
  display: block;
  text-align: right;
  margin-top: ${({ theme }) => theme.spacing.md};

  &:hover {
    color: ${({ theme }) => theme.textColor.primary};
  }

  @media (max-width: ${({ theme }) => theme.breakpoint.mobile}) {
    font-size: ${({ theme }) => theme.fontSize.xxs};
  }
`

export const NoAccount = styled.div`
  color: ${({ theme }) => theme.textColor.secondary};
  font-family: ${({ theme }) => theme.font.primary};
  font-weight: ${({ theme }) => theme.fontWeight.light};
  font-size: ${({ theme }) => theme.fontSize.sm};
  margin-top: ${({ theme }) => theme.spacing.xxxl};

  @media (max-width: ${({ theme }) => theme.breakpoint.mobile}) {
    font-size: ${({ theme }) => theme.fontSize.xs};
    margin-top: ${({ theme }) => theme.spacing.xl};
  }
`

export const RegisterLink = styled(Link)`
  color: ${({ theme }) => theme.textColor.primary};
  font-family: ${({ theme }) => theme.font.primary};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.fontSize.sm};

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: ${({ theme }) => theme.breakpoint.mobile}) {
    font-size: ${({ theme }) => theme.fontSize.xs};
  }
`

export const Button = styled.button`
  width: 100%;
  padding: ${({ theme }) => theme.spacing.sm};
  font-size: ${({ theme }) => theme.fontSize.sm};
  background-color: ${({ theme }) => theme.color.primary};
  color: ${({ theme }) => theme.textColor.white};
  border: none;
  border-radius: ${({ theme }) => theme.borderRadius.md};
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.color.secondary};
  }

  @media (max-width: ${({ theme }) => theme.breakpoint.mobile}) {
    padding: ${({ theme }) => theme.spacing.xs};
    font-size: ${({ theme }) => theme.fontSize.xxs};
  }
`
