import styled, { keyframes } from 'styled-components'


const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`

export const Loader = styled.div`
  width: 75px;
  aspect-ratio: 1;
  box-shadow: 0 0 0 4.5px ${({ theme }) => theme.backgroundColor.primary} inset;
  border-radius: 50%;
  position: relative;
  animation: ${rotate} 7s infinite linear;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: calc(100% + 4.5px);
    left: calc(50% - 18.75px);
    box-shadow: inherit;
    width: 37.5px;
    aspect-ratio: 1;
    border-radius: 50%;
    transform-origin: 50% -42px;
    animation: ${rotate} 1.5s infinite linear;
  }

  &::after {
    animation-delay: -0.75s;
  }
`

export const LoaderContainer = styled.div`
  background-color: ${({ theme }) => theme.backgroundColor.white || '#FFFFFF'};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  max-height: 100vh;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
`
