import React from 'react'

import {
  Foot,
  Contain,
  FooterRightsContainer,
  FooterRights,
  FootLinks,
  FootLink,
  FooterBrand,
  FootName
} from './styles'


const Footer = () => {
  return (
    <Foot>
      <Contain>
        <div>
          <FooterBrand>
            <FootName>Nebulei</FootName>
          </FooterBrand>

          <FooterRightsContainer>
            <FooterRights>
              &copy; {new Date().getFullYear()} Nebulei. All Rights Reserved.
            </FooterRights>
          </FooterRightsContainer>
        </div>

        <FootLinks>
          <FootLink to='/'>Home</FootLink>
          <FootLink to='/about'>About</FootLink>
          <FootLink to='/privacy'>Privacy</FootLink>
          <FootLink to='/tos'>Terms</FootLink>
        </FootLinks>
      </Contain>
    </Foot>
  )
}

export default Footer
