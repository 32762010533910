import React from 'react'

import { Helmet } from 'react-helmet-async'


const SEO = ({ title, description, keywords }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta name="author" content="Nebulei" />
    </Helmet>
  )
}

export default SEO
