import React, { useState } from 'react'
import { useTransition, animated } from '@react-spring/web'
import { formatDistanceToNow } from 'date-fns'
import { useAuth } from '../../hooks/useAuth'
import { usePublication } from '../../hooks/usePublication/usePublication'

import VoteBar from '../VoteBar'
import CommentBox from '../CommentBox'
import DropOut from '../DropOut'
import DropList from '../DropList'

import {
  PublicationWrapper,
  Publication,
  Content,
  AuthorWrapper,
  AuthorType,
  AuthorDisplay,
  AuthorUser,
  PublicationContain,
  PublicationContent,
  PublicationText,
  PublicationDate,
  PublicationAgo,
  AnimatedCommentBox,
  SettingsWrapper,
  SettingsIcon,
  AuthorLink,
  PublicationLink,
} from './styles'


const PublicationItem = ({ publication }) => {
  const initialVote = publication.user_vote
  const [hasVoted, setHasVoted] = useState(Boolean(initialVote))
  const { user } = useAuth()
  const { deletePublication } = usePublication()

  const AnimatedPublicationWrapper = animated(PublicationWrapper)
  const AnimatedCommentBoxStyled = animated(AnimatedCommentBox)

  const handleVoteChange = (newVote) => {
    setHasVoted(Boolean(newVote))
  }

  const handleDelete = async () => {
    try {
      await deletePublication.mutateAsync(publication.id)
    } catch (error) {
      console.error('Failed to delete publication:', error)
    }
  }

  const dropdownItems = [{ label: 'Delete', action: handleDelete }]

  const isAuthor = user && user.username === publication.author?.username

  const transitions = useTransition(hasVoted, {
    from: { opacity: 0, transform: 'translateY(20px)' },
    enter: { opacity: 1, transform: 'translateY(0px)' },
    config: { tension: 220, friction: 20 },
  })

  const truncateContent = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...'
    }
    return text
  }

  return (
    <AnimatedPublicationWrapper>
      <Publication>
        <Content>
          <PublicationDate>
            <PublicationAgo>
              {formatDistanceToNow(new Date(publication.created_at), { addSuffix: true })}
            </PublicationAgo>
          </PublicationDate>

          <AuthorType>{publication.type || 'proposal'}</AuthorType>

          <AuthorWrapper>
            <AuthorLink
              to={`/persona/${publication.author?.username}`}
              onClick={(e) => e.stopPropagation()}
            >
              <AuthorDisplay>
                {publication.author?.display_name || 'Unknown Proposer'}
              </AuthorDisplay>
              <AuthorUser>
                @{publication.author?.username || 'Unknown Proposer'}
              </AuthorUser>
            </AuthorLink>
            {isAuthor && (
              <SettingsWrapper onClick={(e) => e.stopPropagation()}>
                <DropOut
                  trigger={<SettingsIcon size={20} />}
                  direction="left"
                >
                  <DropList
                    items={dropdownItems}
                    onItemSelect={(item) => item.action()}
                  />
                </DropOut>
              </SettingsWrapper>
            )}
          </AuthorWrapper>

          <PublicationLink to={`/publication/${publication.id}`}>
            <PublicationContain>
              <PublicationContent>
                <PublicationText>
                  {truncateContent(publication.content || 'No content available.', 250)}
                </PublicationText>
              </PublicationContent>
            </PublicationContain>
          </PublicationLink>

        </Content>
        <VoteBar
          publicationId={publication.id}
          initialVote={initialVote}
          voteCounts={{
            commend_count: publication.commend_count,
            neutral_count: publication.neutral_count,
            oppose_count: publication.oppose_count,
          }}
          onVoteChange={handleVoteChange}
        />
      </Publication>

      {transitions((styles, item) =>
        item ? (
          <AnimatedCommentBoxStyled style={styles}>
            <CommentBox publicationId={publication.id} />
          </AnimatedCommentBoxStyled>
        ) : null
      )}
    </AnimatedPublicationWrapper>
  )
}

export default PublicationItem
