import React from 'react'
import { InputContainer, Input, ToggleButton } from './styles'
import { TbEye, TbEyeClosed } from "react-icons/tb"


const CustomInput = ({ 
  type, 
  name, 
  placeholder, 
  value = '', 
  onChange, 
  onKeyDown, 
  togglePasswordVisibility, 
  isPasswordVisible 
}) => {
  return (
    <InputContainer>
      <Input
        type={isPasswordVisible && type === 'password' ? 'text' : type}
        name={name}
        placeholder={placeholder}
        value={value ?? ''}
        onChange={onChange}
        onKeyDown={onKeyDown || (() => {})}
      />
      {type === 'password' && (
        <ToggleButton 
          type="button" 
          onClick={() => togglePasswordVisibility(name)} 
          tabIndex={-1}
        >
          {isPasswordVisible ? <TbEye /> : <TbEyeClosed />}
        </ToggleButton>
      )}
    </InputContainer>
  )
}

export default CustomInput
