import styled from 'styled-components'


export const DropListContainer = styled.ul`
  list-style: none;
  background-color: ${({ theme }) => theme.backgroundColor.white};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  box-shadow: ${({ theme }) => theme.shadows.small};
  min-width: 100px;
`

export const DropListItem = styled.li`
  color: ${({ theme }) => theme.textColor.secondary};
  font-family: ${({ theme }) => theme.font.secondary};
  font-size: ${({ theme }) => theme.fontSize.lg};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  padding: ${({ theme }) => theme.spacing.sm} ${({ theme }) => theme.spacing.md};
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.backgroundColor.light};
  }

  @media (max-width: ${({ theme }) => theme.breakpoint.tablet}) {
    font-size: ${({ theme }) => theme.fontSize.sm};
    padding: ${({ theme }) => theme.spacing.sm};
  }

  @media (max-width: ${({ theme }) => theme.breakpoint.mobile}) {
    font-size: ${({ theme }) => theme.fontSize.xs};
    padding: ${({ theme }) => theme.spacing.sm};
  }
`
