import React from 'react'
import { Routes, Route } from 'react-router-dom'
import SEO from '../../common/seo'

import { Home, About, Terms, Privacy } from './views'
import NotFound from '../NotFound'


const Main = () => {
  return (
    <>
    <SEO
      title="Main Menu | Nebulei"
      description="Discover Nebulei's mission to unite diverse professionals, solve global challenges, and foster innovation through collaboration and competition. Learn how we're driving progress in various fields with optimized tools and gamification."
      keywords="Nebulei, innovation, collaboration, problem-solving, gamification, mission, goals, professional community, global challenges, technological solutions"
    />
    <Routes>

        <Route path="" element={<Home />} />
        <Route path="tos" element={<Terms />} />
        <Route path="privacy" element={<Privacy />} />
        <Route path="about" element={<About />} />

        <Route path="*" element={<NotFound />} />

    </Routes>
    </>
  )
}

export default Main