import styled from 'styled-components'


export const Section = styled.section`
  background-color: ${({ theme }) => theme.backgroundColor.white};
  margin-top: ${(props) => props.theme.spacing.xxxl};
  margin-bottom: ${(props) => props.theme.spacing.xxxl};
  display: flex;
  justify-content: center;
  align-items: flex-start;
`

export const Contain = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 800px;
  margin: ${(props) => props.theme.spacing.xxl};
`

export const Header = styled.h1`
  color: ${(props) => props.theme.textColor.primary};
  font-family: ${(props) => props.theme.font.secondary};
  font-weight: ${(props) => props.theme.fontWeight.medium};
  font-size: ${(props) => props.theme.fontSize.xxxl};
  margin-top: ${(props) => props.theme.spacing.xxxl};
  margin-bottom: ${(props) => props.theme.spacing.xl};
  text-align: left;
`

export const Content = styled.div`
  width: 100%;
  margin-top: ${(props) => props.theme.spacing.xxl};
  box-sizing: border-box;
`