import React from 'react'

import { useState, useEffect } from 'react'
import { MessageWrapper, MessageText } from './styles'


const Message = ({ type, message, duration = 3000 }) => {
  const [showMessage, setShowMessage] = useState(false)
  const [isFadingOut, setIsFadingOut] = useState(false)

  useEffect(() => {
    if (message) {
      setShowMessage(true)
      setIsFadingOut(false)

      const fadeTimeout = setTimeout(() => setIsFadingOut(true), duration - 500)
      const removeTimeout = setTimeout(() => setShowMessage(false), duration)

      return () => {
        clearTimeout(fadeTimeout)
        clearTimeout(removeTimeout)
      }
    }
  }, [message, duration])

  if (!showMessage || !message) return null

  return (
  <MessageWrapper type={type} $isFadingOut={isFadingOut}>
    <MessageText>{message}</MessageText>
  </MessageWrapper>
  )
}

export default Message
