import React from 'react'
import Form from '../../../../../components/Form'
import Message from '../../../../../components/Message/Message'


const PasswordChangeForm = ({ loading, error, success, onSubmit }) => {
  const passwordFields = [
    { name: 'old_password', title: 'Old Password', value: '', type: 'password' },
    { name: 'new_password', title: 'New Password', value: '', type: 'password' },
    { name: 'confirm_password', title: 'Confirm Password', value: '', type: 'password' },
  ]

  return (
    <>
      {error && <Message type="error" message={error} />}
      {success && <Message type="success" message={success} />}
      <Form
      fields={passwordFields}
      onSubmit={onSubmit}
      isLoading={loading}
      buttonText="Change Password"
      />
    </>
  )
}

export default PasswordChangeForm
