import styled from 'styled-components'


export const List = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  max-width: 1200px;
  padding: 0 ${({ theme }) => theme.spacing.sm};
  flex-direction: column;

  @media (max-width: ${({ theme }) => theme.breakpoint.tablet}) {
    padding: 0 ${({ theme }) => theme.spacing.xs};
  }
`

export const EmptyMessage = styled.div`
  color: ${({ theme }) => theme.textColor.muted};
  font-family: ${({ theme }) => theme.font.secondary};
  font-weight: ${({ theme }) => theme.fontWeight.light};
  font-size: ${({ theme }) => theme.fontSize.md};
  text-align: center;
`

export const Contain = styled.div`
  padding: ${({ theme }) => theme.spacing.lg};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  box-sizing: border-box;

  @media (max-width: ${({ theme }) => theme.breakpoint.tablet}) {
    padding: ${({ theme }) => theme.spacing.md};
  }
`

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  gap: ${({ theme }) => theme.spacing.md};

`
