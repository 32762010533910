import { useInfiniteQuery } from 'react-query'
import { useAuth } from '../useAuth'
import {
  listPublicationsService,
  listAuthorPublicationsService,
} from '../../services/publicationService'


export const useListPublications = (authorSlug = null) => {
  const { token } = useAuth()

  const {
    data,
    isLoading,
    error,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(
    authorSlug ? ['author-publications', authorSlug] : 'publications',
    ({ pageParam = 1 }) =>
      authorSlug
        ? listAuthorPublicationsService(token, authorSlug, pageParam, 20)
        : listPublicationsService(token, pageParam, 20),
    {
      enabled: !!token,
      getNextPageParam: (lastPage, allPages) => {
        const currentPage = allPages.length
        const totalPages = Math.ceil(lastPage.count / lastPage.pageSize)
        return currentPage < totalPages ? currentPage + 1 : undefined
      },
      staleTime: 1000 * 60 * 5,
      cacheTime: 1000 * 60 * 30,
      retry: 2,
    }
  )

  const publications = data
    ? data.pages.flatMap((page) => page.results)
    : []

  return {
    publications,
    loading: isLoading,
    error,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  }
}
