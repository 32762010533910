import axios from 'axios'
import { 
  myPersonaUrl,
  updateMyPersonaUrl,
  personaRetrieveUrl,
  followPersonaUrl,
  unfollowPersonaUrl
} from './urls'


const getAuthHeader = (token) => ({
  Authorization: `Token ${token}`,
})

const request = async (method, url, token = null, data = {}) => {
  try {
    const config = token ? { headers: getAuthHeader(token) } : {}
    const response = await axios({ method, url, data, ...config })
    return response.data
  } catch (error) {
    console.error('API Error:', error.response?.data || error.message)
    throw error
  }
}

export const getMyPersonaService = (token) =>
  request('get', myPersonaUrl, token)

export const updateMyPersonaService = (token, updatedData, isPartial = false) =>
  request(isPartial ? 'patch' : 'put', updateMyPersonaUrl, token, updatedData)

export const getPersonaBySlugService = (slug, token) =>
  request('get', personaRetrieveUrl(slug), token)

export const followPersonaService = (slug, token) =>
  request('post', followPersonaUrl(slug), token)

export const unfollowPersonaService = (slug, token) =>
  request('post', unfollowPersonaUrl(slug), token)
