import { React, useState } from 'react'
import { useAccount } from '../../../../hooks/useAccount'
import SEO from '../../../../common/seo'

import AccountEdit from './components/AccountEditForm'
import PasswordChange from './components/PasswordChangeForm'
import AccountDelete from './components/AccountDeleteForm'

import { Section, Contain, Header, Content } from './styles'


const Account = () => {
  const { account, updateAccount, deleteAccount, changePassword } = useAccount()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)

  const resetMessages = () => {
    setError(null)
    setSuccess(null)
  }

  const handleUpdateAccount = async (updatedData) => {
    try {
      setLoading(true)
      resetMessages()
      await updateAccount(updatedData)
      setSuccess('Account updated successfully!')
    } catch (err) {
      console.error('Error updating account:', err)
      setError('Failed to update account.')
    } finally {
      setLoading(false)
    }
  }

  const handleDeleteAccount = async ({ password }) => {
    try {
      setLoading(true)
      resetMessages()
      await deleteAccount(password)
      setSuccess('Account deleted successfully.')
    } catch (err) {
      console.error('Error deleting account:', err)
      setError(err.response?.data?.detail || 'Failed to delete account.')
    } finally {
      setLoading(false)
    }
  }

  const handleChangePassword = async (data) => {
    if (data.new_password !== data.confirm_password) {
      setError('Passwords do not match.')
      setSuccess(null)
      return
    }

    try {
      setLoading(true)
      resetMessages()
      await changePassword(data)
      setSuccess('Password updated successfully!')
    } catch (err) {
      console.error('Error updating password:', err)
      setError('Failed to update password.')
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <SEO
        title="Manage Account | Nebulei"
        description="Manage your Nebulei account settings, including password updates, security preferences, and personal details."
        keywords="Nebulei, account settings, manage account, update password, account security, personal details"
      />
      <Section>
        <Contain>
          <Header>Account</Header>
          <Content>
            <AccountEdit
              account={account}
              loading={loading}
              error={error}
              success={success}
              onSubmit={handleUpdateAccount}
            />
          </Content>
        </Contain>
      </Section>

      <Section>
        <Contain>
          <Header>Change Password</Header>
          <Content>
            <PasswordChange
              loading={loading}
              error={error}
              success={success}
              onSubmit={handleChangePassword}
            />
          </Content>
        </Contain>
      </Section>

      <Section>
        <Contain>
          <Header>Delete Account</Header>
          <Content>
            <AccountDelete
              loading={loading}
              error={error}
              success={success}
              onSubmit={handleDeleteAccount}
            />
          </Content>
        </Contain>
      </Section>
    </>
  )
}

export default Account
