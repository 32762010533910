import { useState } from 'react'
import { useQuery } from 'react-query'
import { useAuth } from './useAuth'
import {
  getMyPersonaService,
  getPersonaBySlugService,
  followPersonaService,
  unfollowPersonaService,
} from '../services/personaService'


const CACHE_TIME = 10 * 60 * 1000
const STALE_TIME = 5 * 60 * 1000

export const usePersona = (slug = null) => {
  const { token } = useAuth()

  const { data: persona, isLoading, error, refetch } = useQuery(
    ['persona', slug],
    () => (slug ? getPersonaBySlugService(slug, token) : getMyPersonaService(token)),
    {
      enabled: !!token,
      staleTime: STALE_TIME,
      cacheTime: CACHE_TIME,
    }
  )

  const [followLoading, setFollowLoading] = useState(false)
  const [followError, setFollowError] = useState(null)

  const [unfollowLoading, setUnfollowLoading] = useState(false)
  const [unfollowError, setUnfollowError] = useState(null)

  const followPersona = async () => {
    if (!slug) return
    setFollowLoading(true)
    setFollowError(null)
    try {
      const response = await followPersonaService(slug, token)
      await refetch()
      return response
    } catch (err) {
      setFollowError(err.response?.data || err.message)
      throw err
    } finally {
      setFollowLoading(false)
    }
  }

  const unfollowPersona = async () => {
    if (!slug) return
    setUnfollowLoading(true)
    setUnfollowError(null)
    try {
      const response = await unfollowPersonaService(slug, token)
      await refetch()
      return response
    } catch (err) {
      setUnfollowError(err.response?.data || err.message)
      throw err
    } finally {
      setUnfollowLoading(false)
    }
  }

  return { 
    persona, 
    isLoading, 
    error, 
    followPersona, 
    unfollowPersona,
    followLoading,
    followError,
    unfollowLoading,
    unfollowError
  }
}
