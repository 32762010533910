import React from 'react'

import Form from '../../../../../components/Form'
import Message from '../../../../../components/Message/Message'


const PersonaEditForm = ({ persona, loading, error, success, onSubmit }) => {
  const personaFields = [
    {
      name: 'display_name',
      title: 'Display Name',
      value: persona?.display_name || '',
      type: 'text',
    },
  ]

  return (
    <>
      {error && <Message type="error" message={error} />}
      {success && <Message type="success" message={success} />}
      <Form
        fields={personaFields}
        onSubmit={onSubmit}
        isLoading={loading}
        buttonText="Update Profile"
      />
    </>
  )
}

export default PersonaEditForm
