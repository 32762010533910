import axios from 'axios'
import {
  deleteAccountUrl,
  changePasswordUrl,
  myAccountUrl,
  updateMyAccountUrl,
} from './urls'


const getAuthHeader = (token) => ({
  Authorization: `Token ${token}`,
})

const request = async (method, url, token, data = {}) => {
  try {
    const config = { headers: getAuthHeader(token) }
    const response = await axios({ method, url, data, ...config })
    return response.data
  } catch (error) {
    console.error('API Error:', error.response?.data || error.message || 'Unknown Error')
    throw error
  }
}

export const getMyAccountService = (token) =>
  request('get', myAccountUrl, token)

export const updateMyAccountService = (token, updatedData, isPartial = false) => {
  const method = isPartial ? 'patch' : 'put'
  return request(method, updateMyAccountUrl, token, updatedData)
}

export const deleteMyAccountService = (token, passwordData) =>
  request('post', deleteAccountUrl, token, passwordData)

export const changePasswordService = (token, passwordData) =>
  request('post', changePasswordUrl, token, passwordData)
