import React from 'react'
import Navbar from '../../Navbar/index'
import Footer from '../../Footer/index'

import { LayoutContainer, MainContent } from './styles'


const Layout = ({ children }) => {
  return (
    <LayoutContainer>
      <Navbar />
      <MainContent>
        {children}
      </MainContent>
      <Footer />
    </LayoutContainer>
  )
}
export default Layout
