import { React, useState } from 'react'
import { usePersona } from '../../../../hooks/usePersona'

import SEO from '../../../../common/seo'
import ProfileEditForm from './components/ProfileEditForm'

import { Section, Contain, Header, Content } from './styles'


const Profile = () => {
  const { persona, updatePersona } = usePersona()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)

  const resetMessages = () => {
    setError(null)
    setSuccess(null)
  }

  const handleUpdatePersona = async (updatedData) => {
    try {
      setLoading(true)
      resetMessages()
      await updatePersona(updatedData)
      setSuccess('Profile updated successfully!')
    } catch (err) {
      console.error('Error updating persona:', err)
      setError('Failed to update profile.')
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <SEO
        title="Edit Profile | Nebulei"
        description="Manage your personal profile on Nebulei. Customize your display name, color preferences, and more."
        keywords="Nebulei, profile management, edit profile, customize profile, professional community, collaboration"
      />
      <Section>
        <Contain>
          <Header>Profile</Header>
          <Content>
            <ProfileEditForm
              persona={persona}
              loading={loading}
              error={error}
              success={success}
              onSubmit={handleUpdatePersona}
            />
          </Content>
        </Contain>
      </Section>
    </>
  )
}

export default Profile
