import styled from 'styled-components'

export const Contain = styled.div`
  display: flex;
  align-items: flex-start;
  position: relative;
  width: 100%;
`

export const Area = styled.textarea`
  background-color: ${({ theme, $bgColor }) => theme.backgroundColor[$bgColor] || $bgColor || 'transparent'};
  color: ${({ theme, $textColor }) => theme.textColor[$textColor] || $textColor || theme.textColor.primary};
  font-family: ${({ theme }) => theme.font.primary};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  font-size: ${({ theme, $fontSize }) => $fontSize || theme.fontSize.md};
  border-radius: ${({ theme, $borderRadius }) => $borderRadius || theme.borderRadius.lg};
  border: 4px solid ${({ theme, $borderColor }) => theme.borderColor[$borderColor] || $borderColor || theme.backgroundColor.light};
  padding: ${({ theme, $padding }) => $padding || theme.spacing.lg};
  margin-bottom: ${({ theme, $marginBottom }) => $marginBottom || theme.spacing.base};
  width: 100%;
  min-height: ${({ $minHeight }) => $minHeight || '20vh'};
  line-height: ${({ $lineHeight }) => $lineHeight || '1.5'};
  white-space: pre-wrap;
  resize: none;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${({ theme, $placeholderColor }) => theme.textColor[$placeholderColor] || $placeholderColor || theme.textColor.white};
    font-size: ${({ theme, $placeholderFontSize }) => $placeholderFontSize || theme.fontSize.sm};
    line-height: 1.5;
  }
`
