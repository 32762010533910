import React from 'react'

import { Section, Contain, Content, Heading, Text, Spacer } from './whoStyles'


const Who = () => {
  return (
    <Section>
      <Contain>
        <Heading>
          Who is Nebulei For?
        </Heading>
        <Content>
          <Text>
            Nebulei is for those who seek to push the frontiers of human knowledge and solve complex, meaningful problems. 
            It’s a space for ambitious individuals across fields like engineering, science, and philosophy, who aren’t content 
            with passive learning but instead want to actively shape the world. Our platform is designed for creators, researchers, 
            and thinkers who dare to innovate and challenge conventional wisdom. 
            <Spacer />
            At the heart of Nebulei is deep collaboration. Whether it’s problem-solving, hypothesis testing, or experimental research, 
            we provide tools that foster collective intellectual output. Whether you're a seasoned professional or an emerging thinker, 
            Nebulei is the platform for those driven by curiosity and a desire to make an impact. Together, we aim to not just contribute 
            to global advancements, but lead them.
          </Text>
        </Content>
      </Contain>
    </Section>
  )
}

export default Who

