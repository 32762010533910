import styled, { keyframes } from 'styled-components'


const gradientAnimation = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`

export const Section = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 30vh;
  background: linear-gradient(45deg, #A172FD, #613DC1, #D4CDF4);
  background-size: 200% 200%;
  animation: ${gradientAnimation} 8s ease infinite;

  @media (max-width: 768px) {
    min-height: 20vh;
  }
`

export const Contain = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: ${({ theme }) => theme.spacing.sm};
  text-align: center;

  @media (max-width: 768px) {
    padding: ${({ theme }) => theme.spacing.md};
    max-width: 100%;
  }
`

export const Content = styled.div`
  margin: 0;
  padding: ${({ theme }) => theme.spacing.md};
  line-height: 1.5;

  @media (max-width: 768px) {
    padding: ${({ theme }) => theme.spacing.sm};
  }
`

export const Header = styled.h1`
  color: ${({ theme }) => theme.textColor.white};
  font-family: ${({ theme }) => theme.font.secondary};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.fontSize.xxxl};

  @media (max-width: 768px) {
    font-size: ${({ theme }) => theme.fontSize.xxl};
  }

  @media (max-width: 480px) {
    font-size: ${({ theme }) => theme.fontSize.lg};
  }
`;
