import { API_URL } from './api'


export const accountUrl = `${API_URL}account/`

export const deleteAccountUrl = `${accountUrl}delete/`
export const changePasswordUrl = `${accountUrl}change-password/`
export const myAccountUrl = `${accountUrl}me/`
export const updateMyAccountUrl = `${accountUrl}me/update/`


export const authUrl = `${API_URL}auth/`

export const registerUrl = `${authUrl}register/`
export const loginUrl = `${authUrl}login/`
export const logoutUrl = `${authUrl}logout/`
export const verifyUrl = `${authUrl}verify/`
export const resendVerifyUrl = `${authUrl}resend/`
export const validateUrl = `${authUrl}validate/`
export const resetPasswordRequestUrl = `${authUrl}reset-password/`
export const resetPasswordConfirmUrl = `${authUrl}reset-password-confirm/`


export const personaUrl = `${API_URL}personas/`

export const myPersonaUrl = `${personaUrl}me/`
export const updateMyPersonaUrl = `${personaUrl}me/update/`
export const personaRetrieveUrl  = (slug) => `${personaUrl}${slug}/`

export const followPersonaUrl = (slug) => `${personaUrl}${slug}/follow/`
export const unfollowPersonaUrl = (slug) => `${personaUrl}${slug}/unfollow/`


export const publicationUrl = `${API_URL}publication/`

export const createPublicationUrl = `${publicationUrl}create/`
export const listPublicationsUrl = publicationUrl
export const listAuthorPublicationsUrl = (slug) =>`${publicationUrl}author/${slug}/`;
export const retrievePublicationUrl = (publicationId) => `${publicationUrl}${publicationId}/`
export const deletePublicationUrl = (publicationId) => `${publicationUrl}${publicationId}/delete/`

export const commentCreateUrl = (publicationId) => `${publicationUrl}${publicationId}/comments/create/`
export const commentListUrl = (publicationId) => `${publicationUrl}${publicationId}/comments/`
export const commentRetrieveUrl = (publicationId, commentId) => `${publicationUrl}${publicationId}/comments/${commentId}/`
export const commentDeleteUrl = (publicationId, commentId) => `${publicationUrl}${publicationId}/comments/${commentId}/delete/`

export const voteCreateUrl = (publicationId) => `${publicationUrl}${publicationId}/vote/`
export const voteCountUrl = (publicationId) => `${publicationUrl}${publicationId}/vote-count/`