import { animated } from '@react-spring/web'
import styled, { keyframes, css } from 'styled-components'


const pulse = keyframes`
  0% { opacity: 0.5; }
  50% { opacity: 1; }
  100% { opacity: 0.5; }
`

const shine = keyframes`
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
`

export const Bar = styled.div`
  background-color: ${({ theme }) => theme.backgroundColor.white};
  width: 100%;
`

export const Contain = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

export const Content = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: ${({ theme }) => theme.spacing.sm};

  @media (max-width: ${({ theme }) => theme.breakpoint.mobile}) {
    padding: ${({ theme }) => theme.spacing.xs};
  }
`

export const VoteButton = styled.button`
  background-color: ${({ theme }) => theme.color.transparent};
  color: ${({ theme }) => theme.textColor.primary};
  gap: ${({ theme }) => theme.spacing.xs};
  margin: ${({ theme }) => theme.spacing.xs};
  padding: ${({ theme }) => theme.spacing.sm} ${({ theme }) => theme.spacing.sm};
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid transparent;
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 200px;

  &:hover {
    background-color: ${({ color, theme }) =>
      color === 'commend'
        ? theme.color.vote.commendLight
        : color === 'neutral'
        ? theme.color.vote.neutralLight
        : theme.color.vote.opposeLight};

    span {
      color: ${({ color, theme }) =>
        color === 'commend'
          ? theme.color.vote.commend
          : color === 'neutral'
          ? theme.color.vote.neutral
          : theme.color.vote.oppose};
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoint.mobile}) {
    padding: ${({ theme }) => theme.spacing.xs};
    gap: ${({ theme }) => theme.spacing.xxs};
  }
`

export const Icon = styled.img`
  width: 30px;
  height: 30px;

  @media (max-width: ${({ theme }) => theme.breakpoint.mobile}) {
    width: 24px;
    height: 24px;
  }
`

export const Label = styled.span`
  color: inherit;
  font-family: ${({ theme }) => theme.font.primary};
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  min-width: 20px;

  @media (max-width: ${({ theme }) => theme.breakpoint.mobile}) {
    font-size: ${({ theme }) => theme.fontSize.xs};
  }
`

export const SkeletonLabel = styled.div`
  width: 50px;
  height: 14px;
  background-color: ${({ theme }) => theme.color.grey[200]};
  border-radius: 4px;
  animation: ${pulse} 1.5s infinite;

  @media (max-width: ${({ theme }) => theme.breakpoint.mobile}) {
    width: 40px;
    height: 12px;
  }
`

export const StatusBar = styled.div`
  display: flex;
  height: 10px;
  width: 100%;
  overflow: hidden;

  @media (max-width: ${({ theme }) => theme.breakpoint.mobile}) {
    height: 8px;
  }
`

export const StatusSegment = styled(animated.div)`
  height: 100%;
  background-color: ${({ color, theme }) =>
    color === 'commend'
      ? theme.color.vote.commend
      : color === 'neutral'
      ? theme.color.vote.neutral
      : theme.color.vote.oppose};
  transition: background-color 0.3s ease;
  position: relative;

  ${({ $isHighest }) =>
    $isHighest &&
    css`
      background-image: linear-gradient(
        120deg,
        rgba(255, 255, 255, 0.0) 0%,
        rgba(255, 255, 255, 0.4) 50%,
        rgba(255, 255, 255, 0.0) 100%
      );
      background-repeat: no-repeat;
      background-size: 200% 100%;
      animation: ${shine} 2s linear infinite;
    `}
`
