import styled from 'styled-components'


export const Tag = styled.div`
  background-color: ${({ theme }) => theme.backgroundColor.dark};
  border-radius: 0 ${({ theme }) => theme.borderRadius.pill} ${({ theme }) => theme.borderRadius.pill} 0;
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.sm};
  box-shadow: ${({ theme }) => theme.shadows.small};
  max-height: 35px;
  min-width: 150px;
  max-width: 200px;
  margin: ${({ theme }) => theme.spacing.xs} 0;
  position: relative;

  @media (max-width: ${({ theme }) => theme.breakpoint.tablet}) {
    max-height: 30px;
    min-width: 100px;
    max-width: 150px;
    padding: ${({ theme }) => theme.spacing.xs};
  }

  @media (max-width: ${({ theme }) => theme.breakpoint.mobile}) {
    max-height: 25px;
    min-width: 75px;
    max-width: 100px;
    padding: ${({ theme }) => theme.spacing.xxs};
  }
`

export const TagIcon = styled.img`
  width: 75px;
  height: 75px;
  position: absolute;
  left: -40px;
  top: 50%;
  transform: translateY(-50%);

  @media (max-width: ${({ theme }) => theme.breakpoint.tablet}) {
    width: 60px;
    height: 60px;
    left: -35px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoint.mobile}) {
    width: 45px;
    height: 45px;
    left: -25px;
  }
`

export const TagContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
`

export const TagLabel = styled.span`
  color: ${({ theme }) => theme.textColor.white};
  font-family: ${({ theme }) => theme.font.primary};
  font-size: ${({ theme }) => theme.fontSize.md};
  font-weight: ${({ theme }) => theme.fontWeight.bold};

  @media (max-width: ${({ theme }) => theme.breakpoint.tablet}) {
    font-size: ${({ theme }) => theme.fontSize.sm};
  }

  @media (max-width: ${({ theme }) => theme.breakpoint.mobile}) {
    font-size: ${({ theme }) => theme.fontSize.xs};
  }
`