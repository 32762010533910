import React, { useRef, useCallback } from 'react'

import PublicationItem from '../PublicationItem'
import Load from '../Load'

import { List, Contain, Content, EmptyMessage } from './styles'


const PublicationList = ({ publications = [], loading, emptyMessage, fetchNextPage, hasNextPage, isFetchingNextPage }) => {
  const observer = useRef()

  const lastPublicationRef = useCallback(node => {
    if (loading) return
    if (observer.current) observer.current.disconnect()
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasNextPage) {
        fetchNextPage()
      }
    })
    if (node) observer.current.observe(node)
  }, [loading, hasNextPage, fetchNextPage])

  return (
    <List>
      <Contain>
        <Content>
          {publications.map((publication, index) => {
            if (publications.length === index + 1) {
              return (
                <div ref={lastPublicationRef} key={publication.id}>
                  <PublicationItem publication={publication} />
                </div>
              )
            } else {
              return <PublicationItem key={publication.id} publication={publication} />
            }
          })}
          {loading && <Load />}
          {!loading && publications.length === 0 && <EmptyMessage>{emptyMessage || 'No publications yet.'}</EmptyMessage>}
          {isFetchingNextPage && <Load />}
        </Content>
      </Contain>
    </List>
  )
}

export default PublicationList
