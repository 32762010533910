import React from 'react'

import { LoadContainer, Loader } from './styles'


const Load = () => {
  return (
    <LoadContainer>
      <Loader />
    </LoadContainer>
  )
}

export default Load