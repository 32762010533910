import styled from 'styled-components'


export const LayoutContainer = styled.div`
  display: flex;
`

export const MainContent = styled.div`
  margin-left: 350px;
  flex-grow: 1;
  overflow-y: auto;
  transition: margin-left 0.3s;

  @media (max-width: ${({ theme }) => theme.breakpoint.laptop}) {
    margin-left: 100px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoint.tablet}) {
    margin-left: 100px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoint.mobile}) {
    margin-left: 70px;
  }
`