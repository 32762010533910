import React from 'react'
import { OptionsContain, OptionButton } from './styles'


const Options = ({
  options,
  selectedOption,
  onOptionClick,
  selectedColor = 'secondary',
  defaultColor = 'primary',
  hoverColor = 'secondary',
  textColor = 'white',
  borderColor = 'transparent',
}) => (
  <OptionsContain>
    {options.map((option) => (
      <OptionButton
        key={option.value}
        type="button"
        $isSelected={selectedOption === option.value}
        onClick={() => onOptionClick(option.value)}
        $selectedColor={selectedColor}
        $defaultColor={defaultColor}
        $hoverColor={hoverColor}
        $textColor={textColor}
        $borderColor={borderColor}
      >
        {option.label}
      </OptionButton>
    ))}
  </OptionsContain>
)

export default Options
