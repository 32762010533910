import React from 'react'

import Logo from './NavLogo'

import { BrandContainer, Name, BetaText } from './styles'


const Brand = () => (
  <BrandContainer>
    <Logo />
    <Name>
      Nebulei
      <BetaText>Beta</BetaText>
    </Name>
  </BrandContainer>
)
export default Brand
