import { React, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { usePublication } from '../../hooks/usePublication/usePublication'

import TextArea from '../TextArea'
import Button from '../Button'
import Message from '../Message'

import { 
    CreateCommentForm,
    CreateCommentContainer,
    FormActions 
} from './styles'


const CommentForm = ({ publicationId, currentUser }) => {
  const { createComment } = usePublication()
  const queryClient = useQueryClient()

  const [newComment, setNewComment] = useState('')
  const [errorMessage, setErrorMessage] = useState(null)

  const mutation = useMutation(
    (commentData) => createComment.mutateAsync({ publicationId, commentData }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['comments', publicationId])
        setNewComment('')
        setErrorMessage(null)
      },
      onError: (error) => {
        const message = error?.response?.data?.detail || 'Failed to post comment. Please try again.'
        setErrorMessage(message)
      },
    }
  )

  const handleSubmit = async (e) => {
    e.preventDefault()
    const trimmedComment = newComment.trim()
    if (!trimmedComment) return

    mutation.mutate({ content: trimmedComment })
  }

  return (
    <CreateCommentForm onSubmit={handleSubmit}>
      <CreateCommentContainer>
        <TextArea
          name="content"
          placeholder="Write your comment here.."
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
          bgColor="white"
          textColor="secondary"
          placeholderColor="muted"
          minHeight="100px"
          fontSize="16px"
        />
      </CreateCommentContainer>
      <FormActions>
        <Button 
          type="submit"
          text={mutation.isLoading ? 'Posting..' : 'Comment'}
          bgColor="primary"
          size="base"
          shape="pill"
          disabled={mutation.isLoading}
        />
      </FormActions>
      {errorMessage && <Message type="error" message={errorMessage} />}
    </CreateCommentForm>
  )
}

export default CommentForm


