import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../../../hooks/useAuth'
import SEO from '../../../../common/seo'

import Form from '../../../../components/Form/index'
import Message from '../../../../components/Message/index'

import {
  Section,
  Contain,
  LogoLink,
  Logo,
  LoginBox,
  Header,
  Forgot,
  NoAccount,
  RegisterLink,
} from './styles'
import { logo } from '../../../../assets'


const loginFields = [
  { name: 'email', type: 'text', placeholder: 'Email or Username', label: 'Email or Username' },
  { name: 'password', type: 'password', placeholder: 'Enter your password', label: 'Password' },
]

const Login = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const { login } = useAuth()
  const navigate = useNavigate()

  const handleLoginSubmit = async (formData) => {
    setIsLoading(true)
    setErrorMessage('')

    try {
      await login(formData.email, formData.password)
      navigate('/menu')
    } catch (error) {
      if (error.detail) {
        setErrorMessage(error.detail)
      } else if (error.response?.data?.detail) {
        setErrorMessage(error.response.data.detail)
      } else {
        setErrorMessage('Login failed. Please check your credentials.')
      }
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <SEO
        title="Login to Nebulei"
        description="Log in to Nebulei to access your professional account, connect with innovators, and manage your projects. Secure and easy login for all users."
        keywords="Nebulei login, access Nebulei account, login professional community, secure login Nebulei, Nebulei sign in"
      />
      <Section>
        <Contain>
          <LogoLink to="/">
            <Logo src={logo} alt="Nebulei Logo" />
          </LogoLink>
          <LoginBox>
            <Header>Login to Nebulei</Header>

            {errorMessage && <Message type="error" message={errorMessage} />}

            <Form
              fields={loginFields}
              onSubmit={handleLoginSubmit}
              isLoading={isLoading}
              buttonText="Login"
            />

            <Forgot to="/auth/forgot">Forgot Password?</Forgot>

            <NoAccount>
              Don't have an account? <RegisterLink to="/auth/join">Register</RegisterLink>
            </NoAccount>
          </LoginBox>
        </Contain>
      </Section>
    </>
  )
}

export default Login
