import styled, { keyframes } from 'styled-components'


const l9 = keyframes`
  0% {
    background-position: 0 50%, 50% 50%, 100% 50%;
  }
  20% {
    background-position: 0 0, 50% 50%, 100% 50%;
  }
  40% {
    background-position: 0 100%, 50% 0, 100% 50%;
  }
  60% {
    background-position: 0 50%, 50% 100%, 100% 0;
  }
  80% {
    background-position: 0 50%, 50% 50%, 100% 100%;
  }
  100% {
    background-position: 0 50%, 50% 50%, 100% 50%;
  }
`

export const Loader = styled.div`
  width: 70px;
  height: 40px;
  --g: radial-gradient(
    farthest-side, 
    #0000 calc(95% - 3px), 
    ${({ theme }) => theme.color.primary} calc(100% - 3px) 98%, 
    #0000 101%
  ) no-repeat;
  background: var(--g), var(--g), var(--g);
  background-size: 20px 20px;
  animation: ${l9} 1s infinite alternate ease-in-out;
`

export const LoadContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: transparent;
  position: relative;
  animation: fadeIn 0.5s ease-in-out;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`