import React from 'react'

import { useAccount } from '../../../../hooks/useAccount'
import { usePersona } from '../../../../hooks/usePersona'
import { useListPublications } from '../../../../hooks/usePublication/useListPublications'

import ProfileSection from './components/ProfileSection'
import TagsSection from './components/TagsSection'
import CreatePublicationSection from './components/CreatePublicationSection'

import PublicationList from '../../../../components/PublicationList'

import { Section, Contain, TopWrapper, Top, PublicationSection } from './heroStyles'


const Hero = () => {
  const { account, loading: accountLoading, error: accountError } = useAccount()
  const { persona, loading: personaLoading, error: personaError } = usePersona()
  const {
    publications,
    loading: publicationsLoading,
    error: publicationsError,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useListPublications()

  const loading = accountLoading || personaLoading || publicationsLoading
  const error = accountError || personaError || publicationsError

  return (
    <Section>
      <Contain>
        <TopWrapper>
          <Top>
            <ProfileSection account={account} persona={persona} loading={loading} error={error} />
            <TagsSection persona={persona} loading={loading} error={error} />
          </Top>
        </TopWrapper>
        <CreatePublicationSection />
        <PublicationSection>
          <PublicationList
            publications={publications}
            loading={publicationsLoading}
            emptyMessage="No publications found."
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
            isFetchingNextPage={isFetchingNextPage}
          />
        </PublicationSection>

      </Contain>
    </Section>
  )
}

export default Hero
