import React from 'react'
import SEO from '../../common/seo'

import { useParams } from 'react-router-dom'
import { usePersona } from '../../hooks/usePersona'
import { useListPublications } from '../../hooks/usePublication/useListPublications'

import Button from '../../components/Button'
import Load from '../../components/Load'
import PublicationList from '../../components/PublicationList'

import { 
  Body,
  PersonaSection,
  PersonaCard,
  PersonaIntro,
  PersonaWrapper,
  PersonaName,
  PersonaUser,
  PersonaStats,
  PublicationSection,
  Stat,
  StatLabel,
  StatValue,
} from './styles'


const Profile = () => {
  const { slug } = useParams()
  
  const { 
    persona: viewedPersona, 
    isLoading: loadingViewed, 
    error: errorViewed, 
    followPersona, 
    unfollowPersona,
    followLoading,
    followError,
    unfollowLoading,
    unfollowError
  } = usePersona(slug)
  
  const { 
    persona: myPersona, 
    isLoading: loadingMy, 
    error: errorMy 
  } = usePersona()

  const {
    publications,
    loading: loadingPublications,
    error: errorPublications,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useListPublications(slug)

  if (loadingViewed || loadingMy || loadingPublications) {
    return (
      <>
        <SEO title="Loading... | Profile" />
        <Body>
          <Load />
        </Body>
      </>
    )
  }

  if (errorViewed || errorMy || errorPublications) {
    return (
      <>
        <SEO title="Error | Profile" />
        <Body>
          {errorViewed?.detail || errorMy?.detail || errorPublications?.message || "Error loading profile data. Please try again later."}
        </Body>
      </>
    )
  }

  if (!myPersona || !viewedPersona) {
    return (
      <>
        <SEO title="Error | Profile" />
        <Body>
          Error loading profile data. Please try again later.
        </Body>
      </>
    )
  }

  const isOwnProfile = myPersona.slug === viewedPersona.slug

  const handleFollowClick = () => {
    if (viewedPersona.is_following) {
      unfollowPersona()
    } else {
      followPersona()
    }
  }


  return (
    <>
      <SEO
        title={`${viewedPersona.display_name} | Profile`}
        description="Discover Nebulei's mission to unite diverse professionals..."
        keywords="Nebulei, innovation, collaboration, problem-solving, gamification..."
      />
      <Body>
        <PersonaSection>
          <PersonaCard>

            <PersonaIntro>

              <PersonaWrapper>
                <PersonaName>{viewedPersona.display_name}</PersonaName>
                <PersonaUser>@{viewedPersona.username}</PersonaUser>
              </PersonaWrapper>

              {!isOwnProfile && (
                <Button
                  text={viewedPersona.is_following ? 'Unfollow' : 'Follow'}
                  onClick={handleFollowClick}
                  disabled={followLoading || unfollowLoading}
                  size="base"
                  shape="pill"
                />
              )}

            </PersonaIntro>
            {(followError || unfollowError) && (
              <div>
                {followError?.detail || unfollowError?.detail || "An error occurred. Please try again."}
              </div>
            )}

            <PersonaStats>
              <Stat>
                <StatValue>{viewedPersona.points}</StatValue>
                <StatLabel>Points</StatLabel>
              </Stat>
              <Stat>
                <StatValue>{viewedPersona.experience}</StatValue>
                <StatLabel>Experience</StatLabel>
              </Stat>
              <Stat>
                <StatValue>{viewedPersona.followers_count || 0}</StatValue>
                <StatLabel>Followers</StatLabel>
              </Stat>
              <Stat>
                <StatValue>{viewedPersona.fellows ? viewedPersona.fellows.length : 0}</StatValue>
                <StatLabel>Fellows</StatLabel>
              </Stat>
            </PersonaStats>

          </PersonaCard>



        </PersonaSection>

        <PublicationSection>
          <PublicationList
            publications={publications}
            loading={loadingPublications}
            emptyMessage="This user has not published anything yet."
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
            isFetchingNextPage={isFetchingNextPage}
          />
        </PublicationSection>
      </Body>
    </>
  )
}

export default Profile
