import styled from 'styled-components'


export const BaseButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme, $textColor = 'white' }) => theme?.textColor?.[$textColor] || 'white'};
  font-family: ${({ theme }) => theme?.font?.primary || 'Arial, sans-serif'};
  font-size: ${({ theme, $size }) =>
    $size === 'xl' ? theme?.fontSize?.xl :
    $size === 'lg' ? theme?.fontSize?.lg :
    $size === 'md' ? theme?.fontSize?.md :
    $size === 'sm' ? theme?.fontSize?.base :
    theme?.fontSize?.base || '16px'};
  
  font-weight: ${({ theme }) => theme?.fontWeight?.semibold || 600};
  padding: ${({ $size }) =>
    $size === 'xl' ? '10px 32px' :
    $size === 'lg' ? '8px 32px' :
    $size === 'md' ? '8px 28px' :
    $size === 'sm' ? '8px 24px' :
    '8px 24px'};
  
  border-radius: ${({ theme, $shape }) => theme?.borderRadius?.[$shape] || '8px'};
  background-color: ${({ theme, $bgColor }) => theme?.backgroundColor?.[$bgColor] || theme.backgroundColor.primary};
  border: none;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    opacity: ${({ theme }) => theme?.opacity?.strong || 0.8};
  }

  &:disabled {
    cursor: not-allowed;
    opacity: ${({ theme }) => theme?.opacity?.light || 0.5};
  }
`

export const ButtonIcon = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: ${({ theme, $iconRight }) => ($iconRight ? 0 : theme?.spacing?.sm || '8px')};
  margin-left: ${({ theme, $iconRight }) => ($iconRight ? theme?.spacing?.sm || '8px' : 0)};

  svg {
    width: ${({ theme }) => theme?.spacing?.lg || '24px'};
    height: ${({ theme }) => theme?.spacing?.lg || '24px'};
  }
`
