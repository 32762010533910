import styled from 'styled-components'
import { FaArrowLeft } from 'react-icons/fa'


export const Section = styled.section`
  background-color: ${({ theme }) => theme.backgroundColor.accent};
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
`

export const VerifyBox = styled.div`
  width: 100%;
  max-width: 400px;
  padding: ${({ theme }) => theme.spacing.xxxl};
  background-color: ${({ theme }) => theme.backgroundColor.white};
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  border: 4px solid ${({ theme }) => theme.backgroundColor.light};
  box-shadow: ${({ theme }) => theme.shadows.medium};
  text-align: center;
  position: relative;

  @media (max-width: ${({ theme }) => theme.breakpoint.mobile}) {
    padding: ${({ theme }) => theme.spacing.md};
    max-width: 90%;
  }
`

export const Header = styled.h1`
  color: ${({ theme }) => theme.textColor.primary};
  font-family: ${({ theme }) => theme.font.primary};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.fontSize.xl};
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacing.lg};

  @media (max-width: ${({ theme }) => theme.breakpoint.mobile}) {
    font-size: ${({ theme }) => theme.fontSize.lg};
    margin-bottom: ${({ theme }) => theme.spacing.md};
  }
`

export const EmailInfo = styled.p`
  color: ${({ theme }) => theme.textColor.secondary};
  font-family: ${({ theme }) => theme.font.primary};
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  font-size: ${({ theme }) => theme.fontSize.sm};
  margin-bottom: ${({ theme }) => theme.spacing.lg};

  @media (max-width: ${({ theme }) => theme.breakpoint.mobile}) {
    font-size: ${({ theme }) => theme.fontSize.xs};
    margin-bottom: ${({ theme }) => theme.spacing.md};
  }
`

export const NoResend = styled.div`
  color: ${({ theme }) => theme.textColor.secondary};
  font-family: ${({ theme }) => theme.font.primary};
  font-weight: ${({ theme }) => theme.fontWeight.light};
  font-size: ${({ theme }) => theme.fontSize.sm};
  margin-top: ${({ theme }) => theme.spacing.xxxl};

  @media (max-width: ${({ theme }) => theme.breakpoint.mobile}) {
    font-size: ${({ theme }) => theme.fontSize.xs};
    margin-top: ${({ theme }) => theme.spacing.xl};
  }
`;

export const ResendLink = styled.span`
  color: ${({ theme }) => theme.textColor.primary};
  font-family: ${({ theme }) => theme.font.primary};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.fontSize.sm};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  &:disabled {
    cursor: not-allowed;
    color: ${({ theme }) => theme.textColor.disabled};
  }

  @media (max-width: ${({ theme }) => theme.breakpoint.mobile}) {
    font-size: ${({ theme }) => theme.fontSize.xs};
  }
`

export const BackArrow = styled(FaArrowLeft)`
  position: absolute;
  top: ${({ theme }) => theme.spacing.md};
  left: ${({ theme }) => theme.spacing.md};
  font-size: ${({ theme }) => theme.fontSize.xl};
  color: ${({ theme }) => theme.textColor.secondary};
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.textColor.primary};
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  @media (max-width: ${({ theme }) => theme.breakpoint.mobile}) {
    font-size: ${({ theme }) => theme.fontSize.lg};
    top: ${({ theme }) => theme.spacing.sm};
    left: ${({ theme }) => theme.spacing.sm};
  }
`;
