import { React, useState } from 'react'
import { useAuth } from '../../../../hooks/useAuth'
import SEO from '../../../../common/seo'

import Form from '../../../../components/Form'
import Message from '../../../../components/Message'

import { Section, Contain, LogoLink, Logo, ForgotBox, Header } from './styles'
import { logo } from '../../../../assets'


const Forgot = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [message, setMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const { requestPasswordReset } = useAuth()

  const fields = [
    {
      name: 'email',
      type: 'email',
      placeholder: 'Enter your email',
      label: 'Email',
    },
  ]

  const handleSubmit = async (formData) => {
    setIsLoading(true)
    setMessage('')
    setErrorMessage('')

    try {
      await requestPasswordReset(formData.email)
      setMessage('Password reset email has been sent. Please check your inbox.')
    } catch (error) {
      setErrorMessage(
        error.email?.[0] || error.detail || 'Failed to send password reset email.'
      )
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <SEO
        title="Forgot Password | Reset Your Nebulei Account Access"
        description="Forgot your Nebulei password? Enter your email to receive a password reset link and restore access to your professional Nebulei account."
        keywords="Nebulei password reset, forgot Nebulei password, recover Nebulei account, reset account access, Nebulei account recovery"
      />
      <Section>
        <Contain>
        <LogoLink to="/">
            <Logo src={logo} alt="Nebulei Logo" />
        </LogoLink>
        <ForgotBox>
          <Header>Forgot Password</Header>

          {message && <Message type="success" message={message} />}
          {errorMessage && <Message type="error" message={errorMessage} />}

          <Form
            fields={fields}
            onSubmit={handleSubmit}
            isLoading={isLoading}
            buttonText="Reset Password"
          />
        </ForgotBox>
        </Contain>
      </Section>
    </>
  )
}

export default Forgot
