import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
import { QueryClient, QueryClientProvider } from 'react-query'

import { AuthProvider } from './contexts/authProvider'
import App from './App'

import { ThemeProvider } from 'styled-components'
import { theme } from './css/theme'
import './css/index.css'


const queryClient = new QueryClient()
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <ThemeProvider theme={theme}>
        <Router>
          <QueryClientProvider client={queryClient}>
            <AuthProvider>
              <App />
            </AuthProvider>
          </QueryClientProvider>
        </Router>
      </ThemeProvider>
    </HelmetProvider>
  </React.StrictMode>
)
