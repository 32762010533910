import React from 'react'
import { Routes, Route } from 'react-router-dom'
import { SidebarLayout } from '../../components/Layouts'

import Detail from './views'
import NotFound from '../NotFound'

import { GoHomeFill } from 'react-icons/go'
import { TbSettingsFilled } from 'react-icons/tb'

const publicationLinks = [
  { to: '/menu', label: 'Menu', icon: <GoHomeFill /> },
  { divider: true },
  { to: '/settings/profile', label: 'Settings', icon: <TbSettingsFilled /> },
]

const Publication = () => (
  <Routes>
    <Route
      path=":id"
      element={
        <SidebarLayout links={publicationLinks}>
          <Detail />
        </SidebarLayout>
      }
    />
    <Route path="*" element={<NotFound />} />
  </Routes>
)

export default Publication
