import styled from 'styled-components'


export const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

export const MainContent = styled.div`
  flex-grow: 1;
  overflow: auto; 
`
