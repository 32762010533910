import { Link } from 'react-router-dom'
import styled from 'styled-components'


export const Section = styled.section`
  background-color: ${({ theme }) => theme.backgroundColor.accent};
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
`

export const Contain = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 500px;

  @media (max-width: ${({ theme }) => theme.breakpoint.mobile}) {
    max-width: 90%;
  }
`

export const LogoLink = styled(Link)`
  display: inline-block;
  margin-bottom: ${({ theme }) => theme.spacing.lg};

  @media (max-width: ${({ theme }) => theme.breakpoint.mobile}) {
    margin-bottom: ${({ theme }) => theme.spacing.md};
  }
`

export const Logo = styled.img`
  width: 100px;
  height: auto;

  @media (max-width: ${({ theme }) => theme.breakpoint.mobile}) {
    width: 80px;
  }
`

export const ForgotBox = styled.div`
  width: 100%;
  max-width: 400px;
  padding: ${({ theme }) => theme.spacing.xxl};
  background-color: ${({ theme }) => theme.backgroundColor.white};
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  border: 4px solid ${({ theme }) => theme.backgroundColor.light};
  box-shadow: ${({ theme }) => theme.shadows.medium};
  text-align: center;

  @media (max-width: ${({ theme }) => theme.breakpoint.mobile}) {
    padding: ${({ theme }) => theme.spacing.md};
    max-width: 90%;
  }
`

export const Header = styled.h1`
  color: ${({ theme }) => theme.textColor.primary};
  font-family: ${({ theme }) => theme.font.primary};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.fontSize.xl};
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacing.lg};

  @media (max-width: ${({ theme }) => theme.breakpoint.mobile}) {
    font-size: ${({ theme }) => theme.fontSize.lg};
    margin-bottom: ${({ theme }) => theme.spacing.md};
  }
`