// Register.jsx

import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../../../hooks/useAuth'
import SEO from '../../../../common/seo'

import Form from '../../../../components/Form/index'
import Message from '../../../../components/Message/index'

import {
  Section,
  RegisterBox,
  Contain,
  LogoLink,
  Logo,
  Header,
  NoAccount,
  LoginLink,
} from './styles'
import { logo } from '../../../../assets'

const steps = [
  [
    { name: 'username', type: 'text', placeholder: 'Enter a username', label: 'Username' },
    { name: 'email', type: 'email', placeholder: 'Enter your email', label: 'Email' },
  ],
  [
    { name: 'password', type: 'password', placeholder: 'Enter a password', label: 'Password' },
    { name: 'confirmPassword', type: 'password', placeholder: 'Confirm your password', label: 'Confirm Password' },
  ],
  [
    { name: 'first_name', type: 'text', placeholder: 'Enter first name', label: 'First Name' },
    { name: 'last_name', type: 'text', placeholder: 'Enter last name', label: 'Last Name' },
  ],
]

const Register = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState({})
  const [currentStep, setCurrentStep] = useState(0)
  const [formData, setFormData] = useState({})
  const { register } = useAuth()
  const navigate = useNavigate()

  const handleRegisterSubmit = async (data) => {
    setIsLoading(true)
    setErrors({})

    try {
      await register(data)
      navigate('/auth/verify')
    } catch (error) {
      const newErrors = {}

      if (error.errors) {
        const responseErrors = error.errors
        if (responseErrors.email) {
          newErrors.email = `Email error: ${responseErrors.email[0]}`
        }
        if (responseErrors.username) {
          newErrors.username = `Username error: ${responseErrors.username[0]}`
        }
        if (responseErrors.password) {
          newErrors.password = `Password error: ${responseErrors.password[0]}`
        }
      } else if (error.detail) {
        newErrors.general = error.detail
      } else {
        newErrors.general = 'Registration failed. Please try again.'
      }

      setErrors(newErrors)
    } finally {
      setIsLoading(false)
    }
  }

  const handleNext = (stepData) => {
    setFormData({ ...formData, ...stepData })
    setCurrentStep(currentStep + 1)
  }

  const handleFinalSubmit = (stepData) => {
    const finalData = { ...formData, ...stepData }
    handleRegisterSubmit(finalData)
  }

  return (
    <>
      <SEO
        title="Join Nebulei | Create Your Account Today"
        description="Create your Nebulei account to join a professional community focused on innovation and collaboration. Secure your account and personalize your experience."
        keywords="Nebulei registration, join Nebulei, sign up Nebulei, create account, professional community, secure registration, Nebulei sign up"
      />
      <Section>
        <Contain>
          <LogoLink to="/">
            <Logo src={logo} alt="Nebulei Logo" />
          </LogoLink>
          <RegisterBox>
            <Header>Join Nebulei</Header>

            {errors.general && (
              <Message type="error" message={errors.general} duration={5000} />
            )}

            {Object.keys(errors).map((key, index) => {
              if (key === 'general') return null
              return <Message key={index} type="error" message={errors[key]} />
            })}

            <Form
              fields={steps[currentStep]}
              onSubmit={currentStep === steps.length - 1 ? handleFinalSubmit : handleNext}
              isLoading={isLoading}
              buttonText={currentStep === steps.length - 1 ? 'Register' : 'Next'}
            />

            <NoAccount>
              Already have an account? <LoginLink to="/auth/login">Login</LoginLink>
            </NoAccount>
          </RegisterBox>
        </Contain>
      </Section>
    </>
  )
}

export default Register
