import React from 'react'
import Sidebar from '../../Sidebar'

import { LayoutContainer, MainContent } from './styles'

import { logo } from '../../../assets'

import { GoHomeFill } from 'react-icons/go'
import { TbSettingsFilled } from 'react-icons/tb'


const defaultLinks = [
  { to: '/menu', label: 'Menu', icon: <GoHomeFill /> },
  { divider: true },
  { to: '/settings/profile/', label: 'Settings', icon: <TbSettingsFilled /> },
]

const Layout = ({ children, links }) => {
  const sidebarLinks = links || defaultLinks

  return (
    <LayoutContainer>
      <Sidebar logoSrc={logo} links={sidebarLinks} />
      <MainContent>
        {children}
      </MainContent>
    </LayoutContainer>
  )
}

export default Layout
