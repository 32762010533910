import { React, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'

import { useAuth } from '../../hooks/useAuth'
import { usePublication } from '../../hooks/usePublication/usePublication'
import { useListComments } from '../../hooks/usePublication/useListComments'

import TextArea from '../TextArea'
import Button from '../Button'
import Message from '../Message'
import CommentItem from '../CommentItem'

import { Comment, CommentContainer } from './styles'


const CommentBox = ({ publicationId }) => {
  const queryClient = useQueryClient()
  const { user } = useAuth()
  const { comments } = useListComments(publicationId)
  const { createComment } = usePublication()

  const [comment, setComment] = useState('')
  const [errorMessage, setErrorMessage] = useState(null)

  const userComment = comments?.find(c => c.commenter_id === user?.id)

  const mutation = useMutation(
    newComment => createComment.mutateAsync({ publicationId, commentData: newComment }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['comments', publicationId])
        setComment('')
        setErrorMessage(null)
      },
      onError: error => {
        const message = error?.detail || 'Failed to post comment. Please try again.'
        setErrorMessage(message)
        if (message === 'You have already commented on this publication.') {
          queryClient.invalidateQueries(['comments', publicationId])
        }
      },
    }
  )

  const handleSubmit = e => {
    e.preventDefault()
    const trimmedComment = comment.trim()
    if (trimmedComment) {
      mutation.mutate({ content: trimmedComment })
    }
  }

  return (
    <Comment>
      {errorMessage && <Message type="error" message={errorMessage} />}
      <CommentContainer>
        {userComment ? (
          <CommentItem comment={userComment} />
        ) : (
          <form onSubmit={handleSubmit}>
            <TextArea
              name="content"
              placeholder="Write your comment here.."
              value={comment}
              onChange={e => setComment(e.target.value)}
              bgColor="white"
              textColor="secondary"
              borderColor="white"
              placeholderColor="muted"
              minHeight="50px"
              fontSize="16px"
              padding="none"
            />
            <Button
              type="submit"
              text={mutation.isLoading ? 'Commenting...' : 'Comment'}
              bgColor="primary"
              size="base"
              shape="pill"
              disabled={mutation.isLoading}
            />
          </form>
        )}
      </CommentContainer>
    </Comment>
  )
}

export default CommentBox
