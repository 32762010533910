import styled from 'styled-components'


export const Section = styled.section`
  background-color: ${({ theme }) => theme.backgroundColor.white};
  min-height: 10vh;
  display: flex;
  align-items: center;
  width: 100%;
`

export const Contain = styled.div`
  display: flex;
  width: 100%;
`

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing.xl};
  width: 100%;
  line-height: 1.5;

  @media (max-width: ${({ theme }) => theme.breakpoint.tablet}) {
    justify-content: center;
  }
`

export const PublicationDate = styled.span`
  color: ${({ theme }) => theme.textColor.muted};
  font-family: ${({ theme }) => theme.font.primary};
  font-size: ${({ theme }) => theme.fontSize.lg};
  font-weight: ${({ theme }) => theme.fontWeight.medium};

  @media (max-width: ${({ theme }) => theme.breakpoint.tablet}) {
    font-size: ${({ theme }) => theme.fontSize.md};
  }

  @media (max-width: ${({ theme }) => theme.breakpoint.mobile}) {
    font-size: ${({ theme }) => theme.fontSize.sm};
  }
`

export const PublicationType = styled.div`
  background-color: ${({ theme }) => theme.backgroundColor.primary};
  padding: ${({ theme }) => theme.spacing.xs} ${({ theme }) => theme.spacing.lg};
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  display: flex;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.breakpoint.tablet}) {
    display: none;
  }
`

export const TypeText = styled.span`
  color: ${({ theme }) => theme.textColor.white};
  font-family: ${({ theme }) => theme.font.primary};
  font-size: ${({ theme }) => theme.fontSize.lg};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`