import styled from 'styled-components';

export const Body = styled.body`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
`

export const PersonaSection = styled.section`
  padding: ${({ theme }) => theme.spacing.lg};
  background-color: ${({ theme }) => theme.backgroundColor.space};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;

  @media (max-width: ${({ theme }) => theme.breakpoint.mobile}) {
    padding: ${({ theme }) => theme.spacing.sm};
  }
`

export const PersonaCard = styled.div`
  background-color: ${({ theme }) => theme.backgroundColor.white};
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  box-shadow: ${({ theme }) => theme.shadows.medium};
  overflow: hidden;

  @media (max-width: ${({ theme }) => theme.breakpoint.tablet}) {
    max-width: 100%;
  }
`

export const PersonaIntro = styled.div`
  padding: ${({ theme }) => theme.spacing.lg};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.breakpoint.tablet}) {
    flex-direction: column;
    padding: ${({ theme }) => theme.spacing.md};
    gap: ${({ theme }) => theme.spacing.sm};
  }
`

export const PersonaWrapper = styled.div`
  gap: ${({ theme }) => theme.spacing.sm};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.lg};

  @media (max-width: ${({ theme }) => theme.breakpoint.tablet}) {
    padding: ${({ theme }) => theme.spacing.md};
  }
`

export const PersonaName = styled.h2`
  color: ${({ theme }) => theme.textColor.primary};
  font-family: ${({ theme }) => theme.font.secondary};
  font-size: ${({ theme }) => theme.fontSize.xl};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  text-align: center;

  @media (max-width: ${({ theme }) => theme.breakpoint.mobile}) {
    font-size: ${({ theme }) => theme.fontSize.lg};
  }
`

export const PersonaUser = styled.h3`
  color: ${({ theme }) => theme.textColor.muted};
  font-family: ${({ theme }) => theme.font.primary};
  font-size: ${({ theme }) => theme.fontSize.lg};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  text-align: center;

  @media (max-width: ${({ theme }) => theme.breakpoint.mobile}) {
    font-size: ${({ theme }) => theme.fontSize.md};
  }
`

export const PersonaStats = styled.div`
  display: flex;
  justify-content: space-around;
  padding: ${({ theme }) => theme.spacing.xl};
  gap: ${({ theme }) => theme.spacing.md};

  @media (max-width: ${({ theme }) => theme.breakpoint.tablet}) {
    flex-direction: column;
    align-items: center;
    padding: ${({ theme }) => theme.spacing.md};
  }
`

export const Stat = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const StatLabel = styled.h5`
  color: ${({ theme }) => theme.textColor.muted};
  font-family: ${({ theme }) => theme.font.secondary};
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-weight: ${({ theme }) => theme.fontWeight.light};
  text-align: center;
`

export const StatValue = styled.span`
  color: ${({ theme }) => theme.textColor.primary};
  font-family: ${({ theme }) => theme.font.primary};
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`

export const PersonaMeta = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: ${({ theme }) => theme.spacing.md};

  @media (max-width: ${({ theme }) => theme.breakpoint.mobile}) {
    justify-content: center;
  }
`

export const PublicationSection = styled.section`
  background-color: ${({ theme }) => theme.backgroundColor.space};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 100%;
  padding: ${({ theme }) => theme.spacing.lg};

  @media (max-width: ${({ theme }) => theme.breakpoint.mobile}) {
    padding: ${({ theme }) => theme.spacing.md};
  }
`