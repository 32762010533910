import { React, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useAuth } from '../../../../hooks/useAuth'

import Form from '../../../../components/Form'
import Message from '../../../../components/Message'

import { Section, Box, Header } from './styles'


const Reset = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [message, setMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const { confirmPasswordReset } = useAuth()
  const { token } = useParams()
  const navigate = useNavigate()

  const fields = [
    {
      name: 'new_password',
      type: 'password',
      placeholder: 'Enter your new password',
      label: 'New Password',
    },
    {
      name: 'confirm_password',
      type: 'password',
      placeholder: 'Confirm your new password',
      label: 'Confirm Password',
    },
  ]

  const handleSubmit = async (formData) => {
    setIsLoading(true)
    setMessage('')
    setErrorMessage('')

    if (formData.new_password !== formData.confirm_password) {
      setErrorMessage('Passwords do not match.')
      setIsLoading(false)
      return
    }

    try {
      await confirmPasswordReset(token, formData.new_password)
      setMessage('Your password has been reset successfully.')
      setTimeout(() => {
        navigate('/auth/login')
      }, 3000)
    } catch (error) {
      setErrorMessage(
        error.non_field_errors?.[0] ||
          error.detail ||
          'Failed to reset password. Please try again.'
      )
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Section>
      <Box>
        <Header>Reset Password</Header>

        {message && <Message type="success" message={message} />}
        {errorMessage && <Message type="error" message={errorMessage} />}

        <Form
          fields={fields}
          onSubmit={handleSubmit}
          isLoading={isLoading}
          buttonText="Set New Password"
        />
      </Box>
    </Section>
  )
}

export default Reset
