import React from 'react'
import { Routes, Route } from 'react-router-dom'
import GuestRoute from '../../routes/guestRoute'

import { Register, Login, Verify, Forgot, Reset } from './views'
import NotFound from '../NotFound'


const Auth = () => {
  return (
    <Routes>
      <Route
        path="join"
        element={
          <GuestRoute>
            <Register />
          </GuestRoute>
        }
      />
      <Route
        path="login"
        element={
          <GuestRoute>
            <Login />
          </GuestRoute>
        }
      />
      <Route
        path="verify"
        element={
          <GuestRoute allowAuthenticated={true}>
            <Verify />
          </GuestRoute>
        }
      />
      <Route
        path="forgot"
        element={
          <GuestRoute allowAuthenticated={true}>
            <Forgot />
          </GuestRoute>
        }
      />
      <Route
        path="reset-password/:token"
        element={
          <GuestRoute allowAuthenticated={true}>
            <Reset />
          </GuestRoute>
        }
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

export default Auth
