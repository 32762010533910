import React from 'react'

import {
  Section,
  Contain,
  Content,
  SubHeading,
  Paragraph,
  List,
  ListItem,
  Link
} from './termsStyles'


const Terms = () => {
  return (
    <Section>
      <Contain>
        <Content>
          <SubHeading>Introduction</SubHeading>
          <Paragraph>
            Welcome to Nebulei! These terms and conditions ("Terms") outline the rules and regulations for the use of Nebulei's platform and services (collectively, "Services"). By accessing or using our Services, you agree to comply with these Terms. If you do not agree to all of these Terms, you must not use our Services.
          </Paragraph>

          <SubHeading>1. Intellectual Property Rights</SubHeading>
          <Paragraph>
            Unless otherwise stated, Nebulei and/or its licensors own the intellectual property rights for all material on Nebulei. All intellectual property rights are reserved. You may access this from Nebulei for your own personal use, subject to restrictions set in these Terms.
          </Paragraph>
          <List>
            <ListItem>You must not republish material from Nebulei.</ListItem>
            <ListItem>You must not sell, rent, or sub-license material from Nebulei.</ListItem>
            <ListItem>You must not reproduce, duplicate, or copy material from Nebulei.</ListItem>
            <ListItem>You must not redistribute content from Nebulei without permission.</ListItem>
          </List>

          <SubHeading>2. User Responsibilities</SubHeading>
          <Paragraph>
            By using Nebulei, you agree to the following:
          </Paragraph>
          <List>
            <ListItem>To use the Services only for lawful purposes.</ListItem>
            <ListItem>To not engage in any activity that could harm, disable, or impair the functionality of our platform.</ListItem>
            <ListItem>To provide accurate, current, and complete information when creating an account and using our Services.</ListItem>
            <ListItem>To respect the rights and privacy of other users.</ListItem>
          </List>

          <SubHeading>3. Account Suspension and Termination</SubHeading>
          <Paragraph>
            Nebulei reserves the right to suspend or terminate your access to the Services without prior notice, at our sole discretion, if you violate these Terms or engage in activities that may cause harm to Nebulei or its users.
          </Paragraph>

          <SubHeading>4. Limitation of Liability</SubHeading>
          <Paragraph>
            To the fullest extent permitted by law, Nebulei and its affiliates, officers, directors, employees, or agents shall not be liable for any indirect, incidental, special, consequential, or punitive damages, including loss of profits, data, use, or goodwill, arising from or in connection with your use of the Services.
          </Paragraph>

          <SubHeading>5. Third-Party Links</SubHeading>
          <Paragraph>
            Our Services may contain links to third-party websites or services that are not owned or controlled by Nebulei. We are not responsible for the content, privacy policies, or practices of any third-party websites or services. You acknowledge and agree that Nebulei shall not be liable for any damages or losses caused by your use of third-party websites or services.
          </Paragraph>

          <SubHeading>6. Changes to the Terms</SubHeading>
          <Paragraph>
            Nebulei reserves the right to update or change these Terms at any time. Any changes will be posted on this page with an updated "Effective Date." Your continued use of the Services following any modifications to the Terms constitutes your acceptance of the new Terms.
          </Paragraph>

          <SubHeading>7. Governing Law</SubHeading>
          <Paragraph>
            These Terms shall be governed by and construed in accordance with the laws of [Insert Jurisdiction]. Any disputes arising out of or relating to these Terms or your use of the Services will be subject to the exclusive jurisdiction of the courts located within [Insert Jurisdiction].
          </Paragraph>

          <SubHeading>8. Contact Information</SubHeading>
          <Paragraph>
            If you have any questions or concerns about these Terms, please contact us at: <br />
            Email: <Link href="mailto:lalo@nebulei.com">lalo@nebulei.com</Link> <br />
          </Paragraph>
        </Content>
      </Contain>
    </Section>
  )
}

export default Terms
