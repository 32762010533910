import { useMutation, useQueryClient } from 'react-query'
import { useAuth } from '../useAuth'
import {
  createPublicationService,
  deletePublicationService,
  createCommentService,
  deleteCommentService,
  createVoteService,
} from '../../services/publicationService'


export const usePublication = () => {
  const queryClient = useQueryClient()
  const { token } = useAuth()

  const createPublication = useMutation(
    (formData) => createPublicationService(token, formData),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('publications')
      }
    }
  )

  const deletePublication = useMutation(
    (publicationId) => deletePublicationService(token, publicationId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('publications')
      },
    }
  )

  const createComment = useMutation(
    ({ publicationId, commentData }) => createCommentService(token, publicationId, commentData),
    {
      onSuccess: (data, { publicationId }) => {
        queryClient.invalidateQueries(['comments', publicationId])
      },
    }
  )

  const deleteComment = useMutation(
    ({ publicationId, commentId }) => deleteCommentService(token, publicationId, commentId),
    {
      onSuccess: (data, { publicationId }) => {
        queryClient.invalidateQueries(['comments', publicationId])
      },
    }
  )

  const castVote = useMutation(
    ({ publicationId, voteType }) => createVoteService(token, publicationId, voteType),
    {
      onMutate: async ({ publicationId, voteType }) => {
        await queryClient.cancelQueries(['voteCount', publicationId])

        const previousVoteData = queryClient.getQueryData(['voteCount', publicationId])

        queryClient.setQueryData(['voteCount', publicationId], (oldData) => {
          if (!oldData) return previousVoteData

          return {
            ...oldData,
            user_vote: voteType,
          }
        })

        return { previousVoteData }
      },
      onError: (err, { publicationId }, context) => {
        queryClient.setQueryData(['voteCount', publicationId], context.previousVoteData)
      },
      onSettled: (data, error, { publicationId }) => {
        queryClient.invalidateQueries(['voteCount', publicationId])
      },
    }
  )

  return {
    createPublication,
    deletePublication,
    createComment,
    deleteComment,
    castVote,
  }
}
