import React from 'react'
import { useState, useEffect } from 'react'
import { useQueryClient } from 'react-query'
import { useSpring, animated } from '@react-spring/web'

import { usePublication } from '../../hooks/usePublication/usePublication'
import { useVoteCount } from '../../hooks/usePublication/useVoteCount'

import Load from '../Load'
import Message from '../Message'

import {
  Bar,
  Contain,
  Content,
  VoteButton,
  StatusBar,
  StatusSegment,
  Icon,
  Label,
  SkeletonLabel,
} from './styles'
import { commend, neutral, oppose } from '../../assets'


const VoteBar = ({ publicationId, initialVote, voteCounts, onVoteChange }) => {
  const [selectedVote, setSelectedVote] = useState(initialVote)
  const [errorMessage, setErrorMessage] = useState(null)
  const [optimistic, setOptimistic] = useState(false)
  
  const { castVote } = usePublication()
  const { voteCount, loading, error } = useVoteCount(publicationId)
  const queryClient = useQueryClient()

  const [counts, setCounts] = useState({
    commend_count: voteCounts?.commend_count ?? 0,
    neutral_count: voteCounts?.neutral_count ?? 0,
    oppose_count: voteCounts?.oppose_count ?? 0,
  })

  useEffect(() => {
    if (voteCount && !optimistic) {
      setCounts({
        commend_count: voteCount.commend_count ?? 0,
        neutral_count: voteCount.neutral_count ?? 0,
        oppose_count: voteCount.oppose_count ?? 0,
      })
      setSelectedVote(voteCount.user_vote || null)
      if (onVoteChange) {
        onVoteChange(voteCount.user_vote || null)
      }
    }
  }, [voteCount, optimistic, onVoteChange])

  const getHighestVoteTypes = (counts) => {
    const { commend_count, neutral_count, oppose_count } = counts
    const maxCount = Math.max(commend_count, neutral_count, oppose_count)

    const highestTypes = []

    if (commend_count === maxCount) highestTypes.push('commend')
    if (neutral_count === maxCount) highestTypes.push('neutral')
    if (oppose_count === maxCount) highestTypes.push('oppose')

    return highestTypes
  }

  const highestVoteTypes = getHighestVoteTypes(counts)

  const handleVote = (voteType) => {
    const isDeselecting = selectedVote === voteType
    const newVote = isDeselecting ? null : voteType

    setOptimistic(true)

    setCounts((prevCounts) => {
      const updatedCounts = { ...prevCounts }

      if (isDeselecting) {
        updatedCounts[`${voteType}_count`] = Math.max(
          updatedCounts[`${voteType}_count`] - 1,
          0
        )
      } else {
        if (selectedVote) {
          updatedCounts[`${selectedVote}_count`] = Math.max(
            updatedCounts[`${selectedVote}_count`] - 1,
            0
          )
        }
        updatedCounts[`${voteType}_count`] += 1
      }

      return updatedCounts
    })

    setSelectedVote(newVote)
    if (onVoteChange) {
      onVoteChange(newVote)
    }

    castVote.mutate(
      { publicationId, voteType: newVote },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['voteCount', publicationId])
          queryClient.invalidateQueries(['comments', publicationId])
        },
        onError: () => {
          setSelectedVote(selectedVote)
          setCounts(voteCounts)
          setErrorMessage('Failed to update vote. Please try again.')
        },
        onSettled: () => {
          setOptimistic(false)
        },
      }
    )
  }

  const totalVotes =
    counts.commend_count + counts.neutral_count + counts.oppose_count
  const commendPercentage = totalVotes
    ? (counts.commend_count / totalVotes) * 100
    : 0
  const neutralPercentage = totalVotes
    ? (counts.neutral_count / totalVotes) * 100
    : 0
  const opposePercentage = totalVotes
    ? (counts.oppose_count / totalVotes) * 100
    : 0

  const commendSpring = useSpring({
    width: `${commendPercentage}%`,
    config: { tension: 200, friction: 25 },
  })
  const neutralSpring = useSpring({
    width: `${neutralPercentage}%`,
    config: { tension: 200, friction: 25 },
  })
  const opposeSpring = useSpring({
    width: `${opposePercentage}%`,
    config: { tension: 200, friction: 25 },
  })

  const commendCountSpring = useSpring({
    number: counts.commend_count ?? 0,
    config: { tension: 210, friction: 20 },
  })
  const neutralCountSpring = useSpring({
    number: counts.neutral_count ?? 0,
    config: { tension: 210, friction: 20 },
  })
  const opposeCountSpring = useSpring({
    number: counts.oppose_count ?? 0,
    config: { tension: 210, friction: 20 },
  })

  return (
    <>
      <Bar>
        <Contain>
          <Content>
            {loading ? (
              <Load />
            ) : error ? (
              <Message type="error" message="Error loading votes." />
            ) : (
              [
                {
                  type: 'commend',
                  label: '',
                  icon: commend,
                  springValue: commendCountSpring,
                },
                {
                  type: 'neutral',
                  label: '',
                  icon: neutral,
                  springValue: neutralCountSpring,
                },
                {
                  type: 'oppose',
                  label: '',
                  icon: oppose,
                  springValue: opposeCountSpring,
                },
              ].map(({ type, label, icon, springValue }) => {
                const { number } = springValue

                return (
                  <VoteButton
                    key={type}
                    color={type}
                    selected={selectedVote === type}
                    onClick={() => handleVote(type)}
                  >
                    <Icon src={icon} alt={`${label} icon`} />
                    {loading ? (
                      <SkeletonLabel />
                    ) : (
                      <Label>
                        {label}{' '}
                        {number ? (
                          <animated.span>
                            {number.to((n) => Math.round(n))}
                          </animated.span>
                        ) : (
                          counts[`${type}_count`]
                        )}
                      </Label>
                    )}
                  </VoteButton>
                )
              })
            )}
          </Content>
        </Contain>
      </Bar>

      {errorMessage && <Message type="error" message={errorMessage} />}

      <StatusBar>
        {['commend', 'neutral', 'oppose'].map((type) => (
          <StatusSegment
            key={type}
            style={
              type === 'commend'
                ? commendSpring
                : type === 'neutral'
                ? neutralSpring
                : opposeSpring
            }
            color={type}
            $isHighest={highestVoteTypes.includes(type)}
          />
        ))}
      </StatusBar>
    </>
  )
}

export default VoteBar
