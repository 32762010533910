import axios from 'axios'
import {
  registerUrl,
  loginUrl,
  logoutUrl,
  verifyUrl,
  resendVerifyUrl,
  validateUrl,
  resetPasswordRequestUrl,
  resetPasswordConfirmUrl
} from './urls'


const getAuthHeader = (token) => ({
  Authorization: `Token ${token}`,
})

const request = async (method, url, token = null, data = {}) => {
  try {
    const config = token ? { headers: getAuthHeader(token) } : {}
    const response = await axios({ method, url, data, ...config })
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const handleError = (error) => {
  if (error.response) {
    console.error('API Error:', error.response.data)
    throw error.response.data
  } else {
    console.error('Network Error:', error.message)
    throw error
  }
}

export const registerService = (formData) => request('post', registerUrl, null, formData)
export const loginService = (email, password) => request('post', loginUrl, null, { identifier: email, password })
export const logoutService = (token) => request('post', logoutUrl, token)
export const verifyService = (verificationCode, token) => request('post', verifyUrl, token, { verification_code: verificationCode })
export const resendVerificationCodeService = (token) => request('post', resendVerifyUrl, token)
export const validateService = (token) => request('get', validateUrl, token)
export const requestPasswordResetService = (email) => request('post', resetPasswordRequestUrl, null, { email })
export const confirmPasswordResetService = (token, newPassword) => request('post', resetPasswordConfirmUrl, null, { token, new_password: newPassword })
