import React from 'react'
import { Tag, TagIcon, TagContent, TagLabel } from './styles'
import { experience as experienceIcon } from '../../assets'


const TagExperience = ({ persona, loading, error }) => {
  if (loading || error) {
    return null
  }

  return (
    <Tag>
      <TagIcon src={experienceIcon} alt="Experience Icon" />
      <TagContent>
        <TagLabel>{persona?.experience}</TagLabel>
      </TagContent>
    </Tag>
  )
}

export default TagExperience
