import styled, { keyframes } from 'styled-components'


const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const fadeOutAnimation = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`

export const MessageWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing.md};
  margin: 0;
  border-radius: ${({ theme }) => theme.borderRadius.md};
  background-color: ${({ type, theme }) =>
    type === 'success' ? theme.backgroundColor.successLight : theme.backgroundColor.dangerLight};
  border: 2px solid ${({ type, theme }) =>
    type === 'success' ? theme.backgroundColor.success : theme.backgroundColor.danger};
  
  opacity: ${({ theme }) => theme.opacity.light};
  animation: ${({ $isFadingOut }) => ($isFadingOut ? fadeOutAnimation : fadeIn)} 0.5s forwards;
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  width: 90%;
  max-width: 600px;
`

export const MessageText = styled.p`
  color: ${({ theme }) => theme.textColor.white};
  font-family: ${({ theme }) => theme.font.primary};
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  text-align: center;
`