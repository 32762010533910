import React from 'react'
import SEO from '../../common/seo'

import HeroSection from './sect/index'

const Menu = () => {
  return (
    <>
    <SEO
        title="Menu | Nebulei"
        description="Explore Nebulei's features and tools for collaboration, innovation, and competition. Access opportunities to solve global challenges, join diverse professionals, and drive progress in various fields with Nebulei's optimized platform."
        keywords="Nebulei menu, explore Nebulei, innovation, collaboration, professional community, global challenges, gamification, technological tools, problem-solving"
      />
    <HeroSection />
    </>
  )
}

export default Menu