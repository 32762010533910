import React from 'react'
import { DropListContainer, DropListItem } from './styles'


const DropList = ({ items, onItemSelect, renderItem }) => {
  return (
    <DropListContainer>
      {items.map((item, index) => (
        <DropListItem key={index} onClick={() => onItemSelect(item)}>
          {renderItem ? renderItem(item) : item.label}
        </DropListItem>
      ))}
    </DropListContainer>
  )
}

export default DropList
