import styled from 'styled-components'


export const DropOutWrapper = styled.div`
  position: relative;
  display: inline-block;
`

export const Trigger = styled.div`
  display: inline-block;
`

export const DropOutContainer = styled.div`
  position: fixed;
  visibility: ${({ $isOpen }) => ($isOpen ? 'visible' : 'hidden')};
  opacity: ${({ $isOpen }) => ($isOpen ? 1 : 0)};
  z-index: 1000;
`

export const DropOutContent = styled.div`
  background-color: ${({ theme }) => theme.backgroundColor.white};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  border: 4px solid ${({ theme }) => theme.backgroundColor.light};
  width: 250px;
  min-width: 200px;
  box-shadow: ${({ theme }) => theme.shadows.small};

  @media (max-width: ${({ theme }) => theme.breakpoint.tablet}) {
    max-width: 120px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoint.mobile}) {
    max-width: 70px;
  }
`
