import styled from 'styled-components'


export const Comment = styled.div`
  width: 100%;
  padding: ${({ theme }) => theme.spacing.lg};
  box-sizing: border-box;
  border: 4px solid ${({ theme }) => theme.backgroundColor.light};
  border-top: none;
  background-color: ${({ theme }) => theme.backgroundColor.white};
  border-radius: 0 0 ${({ theme }) => theme.borderRadius.md} ${({ theme }) => theme.borderRadius.md};

  @media (max-width: ${({ theme }) => theme.breakpoint.tablet}) {
    padding: ${({ theme }) => theme.spacing.md};
    border-width: 2px;
    border-radius: 0 0 ${({ theme }) => theme.borderRadius.md} ${({ theme }) => theme.borderRadius.md};
  }
`


export const CommentContainer = styled.div`
  width: 100%;
`