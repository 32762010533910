import React from 'react'

import { Section, Contain, Content, Heading, Text } from './goalStyles'


const Goal = () => {
  return (
    <Section>
      <Contain>
        <Heading>
          Goal
        </Heading>
        <Content>
          <Text>
            At the core of Nebulei is our mission to reshape academic discourse, making it more engaging, inclusive, and dynamic. 
            Our goal is to drive the expansion of human knowledge by fostering collaboration that transcends traditional boundaries. 
            By cultivating meaningful competition, building lasting relationships, and nurturing a space for groundbreaking ideas, 
            we aim to make the process of discovery both rewarding and transformative. As we work towards our MVP, 
            we’re committed to showing how collaboration, curiosity, and creativity can propel us toward a brighter future, 
            uniting diverse minds to push the boundaries of what’s possible.
          </Text>
        </Content>
      </Contain>
    </Section>
  )
}

export default Goal

