import { animated } from '@react-spring/web'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { FiMoreVertical } from 'react-icons/fi'


export const AuthorLink = styled(Link)`
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.sm};

  &:hover {
    text-decoration: none;

    h2 {
      color: ${({ theme }) => theme.textColor.accent};
      text-decoration: underline;
    }
  }
`

export const PublicationLink = styled(Link)`
  color: inherit;
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  text-decoration: none;

   &:hover {
    background-color: ${({ theme }) => theme.backgroundColor.light};
  }
`

export const PublicationWrapper = styled(animated.div)`
  width: 100%;
  position: relative;
  box-sizing: border-box;
`

export const Publication = styled.div`
  background-color: ${({ theme }) => theme.backgroundColor.white};
  border: 4px solid ${({ theme }) => theme.backgroundColor.light};
  width: 100%;
  box-sizing: border-box;
  opacity: 0;
  animation: fadeIn 0.5s ease forwards;

  @media (max-width: ${({ theme }) => theme.breakpoint.tablet}) {
    border-width: 2px;
  }

  @keyframes fadeIn {
    to {
      opacity: 1;
    }
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing.xl};
  position: relative;

  @media (max-width: ${({ theme }) => theme.breakpoint.tablet}) {
    padding: ${({ theme }) => theme.spacing.md};
  }
`

export const AuthorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.sm};
  margin-bottom: ${({ theme }) => theme.spacing.md};
`

export const AuthorType = styled.span`
  color: ${({ theme }) => theme.textColor.secondary};
  font-family: ${({ theme }) => theme.font.primary};
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  background-color: ${({ theme }) => theme.backgroundColor.light};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  padding: ${({ theme }) => theme.spacing.xs} ${({ theme }) => theme.spacing.md};
  position: absolute;
  top: ${({ theme }) => theme.spacing.md};
  right: ${({ theme }) => theme.spacing.md};

  @media (max-width: ${({ theme }) => theme.breakpoint.tablet}) {
    font-size: ${({ theme }) => theme.fontSize.xs};
    padding: ${({ theme }) => theme.spacing.xxs} ${({ theme }) => theme.spacing.sm};
    top: ${({ theme }) => theme.spacing.sm};
    right: ${({ theme }) => theme.spacing.sm};
  }
`

export const AuthorDisplay = styled.h2`
  color: ${({ theme }) => theme.textColor.primary};
  font-family: ${({ theme }) => theme.font.secondary};
  font-size: ${({ theme }) => theme.fontSize.lg};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-top: ${({ theme }) => theme.spacing.xs};


  @media (max-width: ${({ theme }) => theme.breakpoint.tablet}) {
    font-size: ${({ theme }) => theme.fontSize.md};
  }
`

export const AuthorUser = styled.h3`
  color: ${({ theme }) => theme.textColor.muted};
  font-family: ${({ theme }) => theme.font.primary};
  font-size: ${({ theme }) => theme.fontSize.md};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  padding: ${({ theme }) => theme.spacing.xs} ${({ theme }) => theme.spacing.sm};
  border-radius: ${({ theme }) => theme.borderRadius.lg};

  @media (max-width: ${({ theme }) => theme.breakpoint.tablet}) {
    font-size: ${({ theme }) => theme.fontSize.sm};
  }
`

export const PublicationContain = styled.div`
  display: flex;
  flex-direction: column;
`

export const PublicationContent = styled.div`
  padding: ${({ theme }) => theme.spacing.md};
  word-wrap: break-word;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  @media (max-width: ${({ theme }) => theme.breakpoint.tablet}) {
    padding: ${({ theme }) => theme.spacing.sm};
  }
`

export const PublicationText = styled.p`
  color: ${({ theme }) => theme.textColor.secondary};
  font-family: ${({ theme }) => theme.font.secondary};
  font-size: ${({ theme }) => theme.fontSize.md};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  line-height: 1.5;
  user-select: text;
  flex-grow: 1;

  @media (max-width: ${({ theme }) => theme.breakpoint.tablet}) {
    font-size: ${({ theme }) => theme.fontSize.sm};
  }
`

export const PublicationDate = styled.div`
  color: ${({ theme }) => theme.textColor.primary};
  font-family: ${({ theme }) => theme.font.primary};
  font-size: ${({ theme }) => theme.fontSize.md};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};

  @media (max-width: ${({ theme }) => theme.breakpoint.tablet}) {
    font-size: ${({ theme }) => theme.fontSize.sm};
  }
`

export const PublicationAgo = styled.span`
  color: ${({ theme }) => theme.textColor.muted};
  font-family: ${({ theme }) => theme.font.primary};
  font-size: ${({ theme }) => theme.fontSize.xs};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};

  @media (max-width: ${({ theme }) => theme.breakpoint.tablet}) {
    font-size: ${({ theme }) => theme.fontSize.xxs};
  }
`

export const AnimatedCommentBox = styled(animated.div)`
  width: 100%;
  overflow: hidden;
`

export const SettingsWrapper = styled.div`
  margin-left: auto;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
`

export const SettingsIcon = styled(FiMoreVertical)`
  color: ${({ theme }) => theme.textColor.primary};
  padding: ${({ theme }) => theme.spacing.md};
  border-radius: ${({ theme }) => theme.borderRadius.circle};
  z-index: 1000;

  &:hover {
    background-color: ${({ theme }) => theme.backgroundColor.light};
  }

  @media (max-width: ${({ theme }) => theme.breakpoint.tablet}) {
    display: none;
  }
`
