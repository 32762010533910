import styled from 'styled-components'


export const Body = styled.div`
  background-color: ${({ theme }) => theme.backgroundColor.white};
  min-height: 100vh;
`

export const Section = styled.section`
  background-color: ${({ theme }) => theme.backgroundColor.white};
  width: 100%;
  display: flex;
  justify-content: center;
`

export const Contain = styled.div`
  width: 100%;
  max-width: 1200px;
  display: flex;
`

export const Content = styled.div`
  padding: ${({ theme }) => theme.spacing.xxl};
  width: 100%;
  
  @media (max-width: ${({ theme }) => theme.breakpoint.tablet}) {
    padding: ${({ theme }) => theme.spacing.lg};
  }

  @media (max-width: ${({ theme }) => theme.breakpoint.mobile}) {
    padding: ${({ theme }) => theme.spacing.sm};
  }
`
