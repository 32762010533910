import { Link } from 'react-router-dom'
import styled from 'styled-components'


export const Foot = styled.footer`
  background-color: ${({ theme }) => theme.color.transparent};
  padding: ${({ theme }) => theme.spacing.xxl};
  border-top: 4px solid ${({ theme }) => theme.backgroundColor.light};
  position: relative;
`

export const Contain = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  flex-wrap: wrap;
  padding: 0 ${({ theme }) => theme.spacing.lg};

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
    padding: 0 ${({ theme }) => theme.spacing.md};
  }
`

export const FooterBrand = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.md};

  @media (max-width: 768px) {
    margin-bottom: ${({ theme }) => theme.spacing.sm};
  }
`

export const FootName = styled.h3`
  color: ${({ theme }) => theme.textColor.primary};
  font-family: ${({ theme }) => theme.font.secondary};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.fontSize.xl};

  @media (max-width: 768px) {
    font-size: ${({ theme }) => theme.fontSize.lg};
  }
`

export const FootLinks = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin-left: auto;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: ${({ theme }) => theme.spacing.xxl};
    margin-left: 0;
  }
`

export const FootLink = styled(Link)`
  color: ${({ theme }) => theme.textColor.primary};
  font-family: ${({ theme }) => theme.font.primary};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.fontSize.base};
  text-decoration: none;
  margin-left: ${({ theme }) => theme.spacing.md};
  padding: ${({ theme }) => theme.spacing.xs};

  &:hover {
    color: ${({ theme }) => theme.color.primary};
  }

  @media (max-width: 768px) {
    margin-left: 0;
    margin-bottom: ${({ theme }) => theme.spacing.md};
  }
`

export const FooterRightsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: ${({ theme }) => theme.spacing.xl};

  @media (max-width: 768px) {
    justify-content: center;
    margin-top: ${({ theme }) => theme.spacing.md};
  }
`

export const FooterRights = styled.p`
  color: ${({ theme }) => theme.textColor.secondary};
  font-family: ${({ theme }) => theme.font.primary};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${({ theme }) => theme.fontSize.sm};

  @media (max-width: 768px) {
    font-size: ${({ theme }) => theme.fontSize.sm};
    text-align: center;
  }
`
