import React, { useState } from 'react'
import { usePublication } from '../../../../../hooks/usePublication/usePublication'

import TextArea from '../../../../../components/TextArea'
import Button from '../../../../../components/Button'
import Options from '../../../../../components/Options'
import Message from '../../../../../components/Message'

import {
  CreateSection,
  CreateContainer,
  CreateContent,
  OptionsWrapper,
} from '../heroStyles'

const publicationTypes = [
  { value: 'idea', label: 'Idea' },
  { value: 'proposal', label: 'Proposal' },
  { value: 'theory', label: 'Theory' },
]

const CreatePublicationSection = () => {
  const { createPublication } = usePublication()
  const [content, setContent] = useState('')
  const [type, setType] = useState('proposal')
  const [errorMessage, setErrorMessage] = useState('')

  const handleSubmit = async (e) => {
    e.preventDefault()
    const formData = { content, type }
  
    try {
      await createPublication.mutateAsync(formData)
      setContent('')
      setType('proposal')
      setErrorMessage('')
    } catch (error) {
      setErrorMessage(error?.detail || 'An unexpected error occurred.')
    }
  }

  return (
    <CreateSection>
      <CreateContainer>
        <CreateContent>
          {errorMessage && (
            <Message type="error" message={errorMessage} />
          )}
          <form onSubmit={handleSubmit}>
            <OptionsWrapper>
              <Options
                options={publicationTypes}
                selectedOption={type}
                onOptionClick={setType}
                selectedColor="secondary"
                defaultColor="primary"
                hoverColor="secondary"
                textColor="white"
                borderColor="transparent"
              />
            </OptionsWrapper>
            <TextArea
              name="content"
              placeholder="Go on.. Put it out there.."
              value={content}
              onChange={(e) => setContent(e.target.value)}
              bgColor="#f9f9f9"
              textColor="#333"
              minHeight="150px"
              placeholderColor="#999"
              fontSize="20px"
              placeholderFontSize="20px"
              borderRadius="8px"
            />
            <Button text="Publicate" size="large" shape="pill" type="submit" />
          </form>
        </CreateContent>
      </CreateContainer>
    </CreateSection>
  )
}

export default CreatePublicationSection
