import styled from 'styled-components'


export const Section = styled.section`
  background-color: ${({ theme }) => theme.backgroundColor.white};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`

export const Contain = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  transition: padding 0.3s ease;
`

export const TopWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  position: relative;
  top: 0;
  justify-content: space-between;
  align-items: center;
  transition: transform 0.5s ease, opacity 0.5s ease;
  transform: translateY(-20px);
  animation: slideInTop 1s ease forwards;
  margin-bottom: ${({ theme }) => theme.spacing.xl};

  @keyframes slideInTop {
    0% {
      transform: translateY(-20px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
`

export const Top = styled.div`
  padding: ${({ theme }) => theme.spacing.xl};
  width: 100%;
  margin: 0 auto;
  display: flex;
  position: relative;
  top: 0;
  justify-content: space-between;
  align-items: center;
  transition: transform 0.5s ease, opacity 0.5s ease;
  transform: translateY(-20px);
  animation: slideInTop 1s ease forwards;
  margin-bottom: ${({ theme }) => theme.spacing.xl};

  @keyframes slideInTop {
    0% {
      transform: translateY(-20px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoint.desktop}) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: ${({ theme }) => theme.breakpoint.laptop}) {
    justify-content: center;
    align-items: center;
  }

  @media (max-width: ${({ theme }) => theme.breakpoint.tablet}) {
    margin-bottom: 0;
  }
`

export const TagGroup = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.xxxl};
  justify-content: center;
  align-items: center;
  transition: transform 0.4s ease, opacity 0.4s ease;
  transform: translateY(20px);
  animation: slideInBottom 1s ease forwards;

  @keyframes slideInBottom {
    0% {
      transform: translateY(20px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoint.desktop}) {
    margin-top: ${({ theme }) => theme.spacing.xl};
  }

  @media (max-width: ${({ theme }) => theme.breakpoint.mobile}) {
    margin: 0;
    gap: ${({ theme }) => theme.spacing.md};
    flex-direction: column;
    justify-content: center;
  }
`

export const ProfileWrapper = styled.div`
  @media (max-width: ${({ theme }) => theme.breakpoint.laptop}) {
    display: none;
  }
`

export const PublicationSection = styled.section`
  background-color: ${({ theme }) => theme.backgroundColor.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`
export const CreateSection = styled.div`
  margin-top: ${({ theme }) => theme.spacing.xxl};
  margin-bottom: ${({ theme }) => theme.spacing.xxl};
`

export const CreateContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: ${({ theme }) => theme.spacing.sm};

  @media (max-width: ${({ theme }) => theme.breakpoint.mobile}) {
    padding: ${({ theme }) => theme.spacing.sm};
  }
`

export const CreateContent = styled.div`

`

export const OptionsWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.md};
`
