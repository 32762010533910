import { Link } from 'react-router-dom'
import styled from 'styled-components'


export const Nav = styled.nav`
  background-color: ${({ theme }) => theme.color.transparent};
  padding: ${({ theme }) => theme.spacing.md};
  position: relative;
`

export const Contain = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  flex-wrap: wrap;
  padding: 0 ${({ theme }) => theme.spacing.lg};

  @media (max-width: 768px) {
    justify-content: center;
    padding: 0 ${({ theme }) => theme.spacing.md};
  }
`

export const BrandContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`

export const LogoImage = styled.img`
  height: 50px;
  margin-right: ${({ theme }) => theme.spacing.sm};
`

export const Name = styled.h1`
  color: ${({ theme }) => theme.textColor.primary};
  font-family: ${({ theme }) => theme.font.secondary};
  font-weight: ${({ theme }) => theme.fontWeight.extrabold};
  font-size: ${({ theme }) => theme.fontSize.xl};
  position: relative;
`

export const BetaText = styled.span`
  color: ${({ theme }) => theme.textColor.primary};
  background-color: ${({ theme }) => theme.backgroundColor.light};
  font-family: ${({ theme }) => theme.font.primary};
  font-size: ${({ theme }) => theme.fontSize.sm};
  opacity: ${({ theme }) => theme.opacity.strong};
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  padding: 2px 4px;
  position: absolute;
  top: 7px;
  right: -42px;
`

export const NavLinksContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    display: none;
  }
`

export const StyledNavLink = styled(Link)`
  color: ${({ theme }) => theme.textColor.secondary};
  font-family: ${({ theme }) => theme.font.primary};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.fontSize.md};
  text-decoration: none;
  margin-left: ${({ theme }) => theme.spacing.md};
  padding: ${({ theme }) => theme.spacing.sm};
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  transition: color 0.3s ease;

  &:hover {
    background-color: ${({ theme }) => theme.backgroundColor.light};
  }

  @media (max-width: 768px) {
    margin-left: 0;
    margin-bottom: ${({ theme }) => theme.spacing.md};
  }
`
