import styled from 'styled-components'


export const CreateCommentForm = styled.form`
  display: flex;
  flex-direction: column;
`

export const CreateCommentContainer = styled.div`
  flex: 1;
`

export const FormActions = styled.div`
  display: flex;
  justify-content: flex-end;
`
