import React from 'react'
import { 
  Section,
  Contain,
  Content,
  AuthorLink,
  AuthorWrapper,
  AuthorDisplay,
  AuthorUser,
  ContentWrapper,
  ContentText,
} from './contentStyles'


const ContentSection = ({ publication }) => {
  return (
    <Section>
      <Contain>
        <Content>
          <AuthorWrapper>
            <AuthorLink
              to={`/persona/${publication.author?.username}`}
              onClick={(e) => e.stopPropagation()}
            >
              <AuthorDisplay>{publication?.author?.display_name || 'Unknown Author'}</AuthorDisplay>
              <AuthorUser>@{publication?.author?.username || 'unknown_user'}</AuthorUser>
            </AuthorLink>
          </AuthorWrapper>
          <ContentWrapper>
            <ContentText>{publication?.content || 'No content available'}</ContentText>
          </ContentWrapper>
        </Content>
      </Contain>
    </Section>
  )
}

export default ContentSection
