import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useAuth } from '../../../../hooks/useAuth'
import { useRetrievePublication } from '../../../../hooks/usePublication/useRetrievePublication'
import { useListComments } from '../../../../hooks/usePublication/useListComments'
import { useVoteCount } from '../../../../hooks/usePublication/useVoteCount'

import SEO from '../../../../common/seo'
import VoteBar from '../../../../components/VoteBar'
import CommentList from '../../../../components/CommentList'
import CommentForm from '../../../../components/CommentForm'
import Load from '../../../../components/Load'

import { HeaderSection, ContentSection } from './sect'
import { Body, Section, Contain, Content } from './styles'


const Detail = () => {
  const [userVote, setUserVote] = useState(null)
  const [hasCommented, setHasCommented] = useState(false)

  const { id: publicationId } = useParams()
  const { user } = useAuth()
  
  const { publication, loading: pubLoading } = useRetrievePublication(publicationId)
  const { comments, loading: commentsLoading, loadMore, hasMore } = useListComments(publicationId)
  const { voteCount, loading: voteLoading } = useVoteCount(publicationId)
  const isLoading = pubLoading || commentsLoading || voteLoading

  useEffect(() => {
    if (voteCount) {
      setUserVote(voteCount.user_vote || null)
    }
  }, [voteCount])

  useEffect(() => {
    if (user && comments) {
      const commented = comments.some(comment => comment.commenter_id === user.id)
      setHasCommented(commented)
    }
  }, [user, comments])

  
  const publicationDate = publication?.created_at
    ? new Date(publication.created_at).toLocaleDateString()
    : 'Unknown Date'

  return (
    <>
      <SEO
        title={publication ? `${publication.author?.display_name || 'Unknown'} says ${publication.meta_title || 'Untitled'} | Nebulei` : 'Loading...'}
        description={`${publication?.author?.display_name || 'Unknown'} published a ${publication?.type || 'Proposal'} on ${publicationDate} - ${publication?.meta_description || 'No description available'}`}
        keywords={`Nebulei, publication, ${publication?.type || 'Unknown Type'}`}
      />
      
      <Body>
        {isLoading && <Load />}

        {!isLoading && (
          <>
            <HeaderSection publication={publication} />
            <ContentSection publication={publication} />

            <VoteBar 
              publicationId={publicationId}
              initialVote={voteCount?.user_vote || null}
              voteCounts={voteCount}
              onVoteChange={(newVote) => setUserVote(newVote)}
            />

            <Section>
              <Contain>
                <Content>
                  {userVote && !hasCommented && (
                    <CommentForm 
                      publicationId={publicationId} 
                      currentUser={user} 
                    />
                  )}
                </Content>
              </Contain>
            </Section>

            <CommentList
              comments={comments}
              loading={commentsLoading}
              loadMore={loadMore}
              hasMore={hasMore}
            />
          </>
        )}
      </Body>
    </>
  )
}

export default Detail
