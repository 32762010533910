import { useState } from 'react'
import { useQuery } from 'react-query'
import { useAuth } from '../../hooks/useAuth'
import { listCommentsService } from '../../services/publicationService'


export const useListComments = (publicationId, initialPage = 1, pageSize = 20) => {
  const { token } = useAuth()
  const [page, setPage] = useState(initialPage)

  const { data, isLoading, error, isFetching } = useQuery(
    ['comments', publicationId, page],
    () => listCommentsService(token, publicationId, page, pageSize),
    {
      enabled: !!publicationId && !!token,
      keepPreviousData: true,
      staleTime: 5 * 60 * 1000,
      cacheTime: 10 * 60 * 1000,
    }
  )

  const loadMore = () => {
    if (data?.next) {
      setPage((prevPage) => prevPage + 1)
    }
  }

  const comments = data?.results || []
  const hasMore = !!data?.next

  return { comments, loading: isLoading || isFetching, error, loadMore, hasMore }
}
