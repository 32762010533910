import styled from 'styled-components'


export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`

export const Input = styled.input`
  color: ${({ theme }) => theme.textColor.primary};
  font-family: ${({ theme }) => theme.font.primary};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${({ theme }) => theme.fontSize.md};
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  border: 2px solid ${({ theme }) => theme.backgroundColor.light};
  padding: ${({ theme }) => theme.spacing.base};
  margin-bottom: ${({ theme }) => theme.spacing.base};
  width: 100%;

  &:focus {
    outline: none;
  }

  @media (max-width: ${({ theme }) => theme.breakpoint.tablet}) {
    font-size: ${({ theme }) => theme.fontSize.base};
    padding: ${({ theme }) => theme.spacing.base};
    margin-bottom: 0;
  }

  @media (max-width: ${({ theme }) => theme.breakpoint.mobile}) {
    font-size: ${({ theme }) => theme.fontSize.sm};
    padding: ${({ theme }) => theme.spacing.sm};
  }
`

export const ToggleButton = styled.button`
  color: ${({ theme }) => theme.textColor.muted};
  position: absolute;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
  padding: 5px;

  &:focus {
    outline: none;
  }

  @media (max-width: ${({ theme }) => theme.breakpoint.mobile}) {
    font-size: 20px;
    right: 8px;
  }
`
