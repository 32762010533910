import React from 'react'

import SEO from '../common/seo'
import Button from '../components/Button/index'

import styled from 'styled-components'


const NotFound = () => {
  return (
    <>
      <SEO
        title="Page Not Found | Nebulei"
        description="Oops! The page you're looking for doesn't exist. Explore Nebulei's innovative platform or return to the homepage to discover how we empower professionals to tackle global challenges."
        keywords="404, page not found, Nebulei, error, broken link, missing page, return to homepage, explore Nebulei"
      />
      <Section>
        <Contain>
          <Content>
            <Header>404 - Page Not Found</Header>
            <Text>
              Oops! The page you're looking for doesn't exist. 
              <br />
              It might have been removed, or you may have mistyped the URL.
            </Text>
            <Button 
              text="Return to Homepage"
              to="/"
              size="lg"
            />
          </Content>
        </Contain>
      </Section>
    </>
  )
}

export default NotFound

const Section = styled.section`
  background-color: ${(props) => props.theme.backgroundColor.white};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`

const Contain = styled.div`
  background-color: ${(props) => props.theme.backgroundColor.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 600px;

  @media (max-width: ${(props) => props.theme.breakpoint.mobile}) {
    max-width: 100%;
  }
`

const Content = styled.div`
  text-align: center;

  @media (max-width: ${(props) => props.theme.breakpoint.mobile}) {
    width: 100%;
  }
`

const Header = styled.h1`
  color: ${(props) => props.theme.textColor.primary};
  font-family: ${(props) => props.theme.font.secondary};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  font-size: ${(props) => props.theme.fontSize.xxxl};
  margin-bottom: ${(props) => props.theme.spacing.md};

  @media (max-width: ${(props) => props.theme.breakpoint.mobile}) {
    font-size: ${(props) => props.theme.fontSize.xl};
  }
`

const Text = styled.p`
  color: ${(props) => props.theme.textColor.secondary};
  font-family: ${(props) => props.theme.font.primary};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  font-size: ${(props) => props.theme.fontSize.lg};
  margin-bottom: ${(props) => props.theme.spacing.lg};

  @media (max-width: ${(props) => props.theme.breakpoint.mobile}) {
    font-size: ${(props) => props.theme.fontSize.md};
    margin-bottom: ${(props) => props.theme.spacing.md};
  }
`
