import { useQuery } from 'react-query'
import { useAuth } from '../useAuth'
import { getVoteCountService } from '../../services/publicationService'


export const useVoteCount = (publicationId) => {
  const { token } = useAuth()

  const { data, isLoading, error } = useQuery(
    ['voteCount', publicationId],
    () => getVoteCountService(token, publicationId),
    {
      enabled: !!publicationId && !!token,
      staleTime: 5 * 60 * 1000,
      cacheTime: 10 * 60 * 1000,
    }
  )

  return { voteCount: data, loading: isLoading, error }
}
