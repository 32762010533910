import React from 'react'

import { useAuth } from '../hooks/useAuth'
import { Navigate } from 'react-router-dom'


const GuestRoute = ({ children, allowAuthenticated = false }) => {
  const { token } = useAuth()

  if (token && !allowAuthenticated) {
    return <Navigate to="/menu" />
  }

  return children
}

export default GuestRoute
