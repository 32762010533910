import styled from 'styled-components'


const getThemeColor = (theme, colorKey) => {
  const keys = colorKey.split('.')
  
  const color =
    keys.reduce((acc, key) => acc?.[key], theme.color) ||
    keys.reduce((acc, key) => acc?.[key], theme.backgroundColor)

  return color || colorKey
}

export const OptionsContain = styled.div`
  gap: ${({ theme }) => theme.spacing.md};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: ${({ theme }) => theme.breakpoint.tablet}) {
    gap: ${({ theme }) => theme.spacing.sm};
  
  }

  @media (max-width: ${({ theme }) => theme.breakpoint.mobile}) {
    gap: ${({ theme }) => theme.spacing.xs};
  }
`

export const OptionButton = styled.button`
  background-color: ${({ theme, $isSelected, $selectedColor, $defaultColor }) =>
    $isSelected
      ? getThemeColor(theme, $selectedColor)
      : getThemeColor(theme, $defaultColor)};
  color: ${({ theme, $textColor }) => getThemeColor(theme, $textColor)};
  font-family: ${({ theme }) => theme.font.secondary};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.fontSize.md};
  border-radius: ${({ theme }) => theme.borderRadius.pill};
  border: 4px solid ${({ theme, $borderColor }) =>
    getThemeColor(theme, $borderColor)};
  padding: ${({ theme }) => theme.spacing.sm} ${({ theme }) => theme.spacing.md};
  cursor: pointer;
  width: 150px;
  max-width: 100%;
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.2s ease;

  &:hover {
    background-color: ${({ theme, $hoverColor }) =>
      getThemeColor(theme, $hoverColor)};
    transform: translateY(-2px);
  }

  @media (max-width: ${({ theme }) => theme.breakpoint.tablet}) {
    font-size: ${({ theme }) => theme.fontSize.sm};
  }
`