import { Link } from 'react-router-dom'

import styled from 'styled-components'


export const AuthorLink = styled(Link)`
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.sm};

  &:hover {
    text-decoration: none;

    h2 {
      color: ${({ theme }) => theme.textColor.accent};
      text-decoration: underline;
    }
  }
`

export const Section = styled.section`
  background-color: ${({ theme }) => theme.backgroundColor.white};
  width: 100%;
  display: flex;
  justify-content: center;
`

export const Contain = styled.div`
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: center;
`

export const Content = styled.div`
  padding: ${({ theme }) => theme.spacing.xxl};
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoint.tablet}) {
    padding: ${({ theme }) => theme.spacing.lg};
  }

  @media (max-width: ${({ theme }) => theme.breakpoint.mobile}) {
    padding: ${({ theme }) => theme.spacing.sm};
  }
`

export const AuthorWrapper = styled.div`
  gap: ${({ theme }) => theme.spacing.sm};
  width: 100%;
  display: flex;
  align-items: center;
`

export const AuthorDisplay = styled.h2`
  color: ${({ theme }) => theme.textColor.primary};
  font-family: ${({ theme }) => theme.font.secondary};
  font-size: ${({ theme }) => theme.fontSize.xl};
  font-weight: ${({ theme }) => theme.fontWeight.bold};

  @media (max-width: ${({ theme }) => theme.breakpoint.tablet}) {
    font-size: ${({ theme }) => theme.fontSize.lg};
  }

  @media (max-width: ${({ theme }) => theme.breakpoint.mobile}) {
    font-size: ${({ theme }) => theme.fontSize.md};
  }
`

export const AuthorUser = styled.h3`
  color: ${({ theme }) => theme.textColor.muted};
  font-family: ${({ theme }) => theme.font.secondary};
  font-size: ${({ theme }) => theme.fontSize.lg};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  padding: ${({ theme }) => theme.spacing.sm} ${({ theme }) => theme.spacing.sm};
  border-radius: ${({ theme }) => theme.borderRadius.lg};

  @media (max-width: ${({ theme }) => theme.breakpoint.tablet}) {
    font-size: ${({ theme }) => theme.fontSize.md};
  }

  @media (max-width: ${({ theme }) => theme.breakpoint.mobile}) {
    font-size: ${({ theme }) => theme.fontSize.sm};
  }
`

export const ContentWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing.md};
  margin-bottom: ${({ theme }) => theme.spacing.md};
  line-height: 1.5;
`


export const ContentText = styled.p`
  color: ${({ theme }) => theme.textColor.secondary};
  font-family: ${({ theme }) => theme.font.secondary};
  font-size: ${({ theme }) => theme.fontSize.md};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  user-select: text;

  @media (max-width: ${({ theme }) => theme.breakpoint.tablet}) {
    font-size: ${({ theme }) => theme.fontSize.sm};
  }

  @media (max-width: ${({ theme }) => theme.breakpoint.mobile}) {
    font-size: ${({ theme }) => theme.fontSize.sm};
  }
`
