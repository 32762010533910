import React from 'react'
import Form from '../../../../../components/Form'
import Message from '../../../../../components/Message/Message'


const AccountDeleteForm = ({ loading, error, success, onSubmit }) => {
  const accountFields = [{ name: 'password', title: 'Current Password', value: '', type: 'password' }]

  return (
    <>
      {error && <Message type="error" message={error} />}
      {success && <Message type="success" message={success} />}
      <Form
      fields={accountFields}
      onSubmit={onSubmit}
      isLoading={loading}
      buttonText="Delete Account"
      buttonColor="danger"
      />
    </>
  )
}

export default AccountDeleteForm
