import React from 'react'

import {
  Section,
  Contain,
  Content,
  SubHeading,
  Paragraph,
  List,
  ListItem,
  Link
} from './privacyStyles'


const Privacy = () => {
  return (
    <Section>
      <Contain>
        <Content>
          <Paragraph>
            Nebulei ("we," "our," or "us") values your privacy and is committed to protecting your personal data. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our platform, websites, and services (collectively, "Services"). Please read this Privacy Policy carefully to understand our views and practices regarding your personal information and how we will treat it.
          </Paragraph>
          <Paragraph>
            By accessing or using our Services, you agree to the terms outlined in this Privacy Policy. If you do not agree, please refrain from using our Services.
          </Paragraph>

          <SubHeading>1. Information We Collect</SubHeading>
          <Paragraph>
            We may collect and process the following categories of personal information about you:
          </Paragraph>

          <SubHeading>1.1 Personal Data You Provide</SubHeading>
          <List>
            <ListItem>Account Information: When you register or manage your account, we collect your username, email address, and other registration details.</ListItem>
            <ListItem>Profile Data: Information you provide in your user profile, such as your occupation, skills, and interests.</ListItem>
            <ListItem>Communication Data: Details of any communication you send to us, including support inquiries or feedback.</ListItem>
          </List>

          <SubHeading>1.2 Data Collected Automatically</SubHeading>
          <List>
            <ListItem>Device Information: Details about the devices you use to access our Services, such as IP addresses, browser type, and operating system.</ListItem>
            <ListItem>Usage Data: Information about your interactions with our Services, including the pages you visit, the actions you take, and the time spent on each section.</ListItem>
            <ListItem>Cookies and Tracking Data: We use cookies and similar tracking technologies to enhance your user experience and collect data on how you use our platform.</ListItem>
          </List>

          <SubHeading>1.3 Third-Party Information</SubHeading>
          <Paragraph>
            If you choose to connect your account with third-party services like Google or GitHub, we may receive information from these services as authorized by you.
          </Paragraph>

          <SubHeading>2. How We Use Your Information</SubHeading>
          <Paragraph>
            Nebulei processes personal data for the following purposes:
          </Paragraph>
          <List>
            <ListItem>Account Management: To register, authenticate, and manage your account.</ListItem>
            <ListItem>Personalization: To provide personalized content and recommendations based on your interests and activities.</ListItem>
            <ListItem>Communication: To respond to your inquiries, provide support, and keep you informed about changes to our services, policies, or new features.</ListItem>
            <ListItem>Improvement of Services: To improve and optimize our platform, including analyzing user activity and feedback.</ListItem>
            <ListItem>Security: To monitor, prevent, and address fraud, unauthorized access, and other illegal activities.</ListItem>
            <ListItem>Legal Compliance: To comply with applicable laws, regulations, and legal processes.</ListItem>
          </List>

          <SubHeading>3. Data Sharing and Disclosure</SubHeading>
          <Paragraph>
            We do not sell your personal data. However, we may share your information in the following circumstances:
          </Paragraph>
          <List>
            <ListItem>Service Providers: We may share your data with trusted third-party vendors, contractors, and service providers who assist us in operating our platform and providing services to you.</ListItem>
            <ListItem>Legal Requirements: We may disclose your information to comply with legal obligations, court orders, or governmental requests.</ListItem>
            <ListItem>Business Transfers: In the event of a merger, acquisition, or sale of assets, your personal data may be transferred as part of the transaction.</ListItem>
            <ListItem>User Collaboration: With your consent, we may share information with other users for collaborative projects.</ListItem>
          </List>

          <SubHeading>4. Data Security</SubHeading>
          <Paragraph>
            We employ technical and organizational security measures to protect your data from unauthorized access, disclosure, or destruction. While we strive to ensure the security of your data, no transmission over the internet or storage method is 100% secure. We cannot guarantee the absolute security of your information.
          </Paragraph>

          <SubHeading>5. Data Retention</SubHeading>
          <Paragraph>
            We retain personal data for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required by law. Once your data is no longer needed, we will securely delete or anonymize it.
          </Paragraph>

          <SubHeading>6. Your Rights and Choices</SubHeading>
          <Paragraph>
            You have the following rights regarding your personal data:
          </Paragraph>
          <List>
            <ListItem>Access and Correction: You have the right to access the personal information we hold about you and request corrections if necessary.</ListItem>
            <ListItem>Deletion: You may request the deletion of your personal data under certain conditions.</ListItem>
            <ListItem>Data Portability: You can request a copy of your personal data in a structured, machine-readable format.</ListItem>
            <ListItem>Objection and Restriction: You can object to or request the restriction of certain data processing activities.</ListItem>
            <ListItem>Consent Withdrawal: If we process your data based on your consent, you may withdraw this consent at any time.</ListItem>
          </List>

          <SubHeading>7. Cookies and Tracking Technologies</SubHeading>
          <Paragraph>
            Nebulei uses cookies and other tracking technologies to enhance user experience and analyze usage patterns. You can manage your cookie preferences through your browser settings. Disabling cookies may affect certain functionalities of our Services.
          </Paragraph>

          <SubHeading>8. Third-Party Links</SubHeading>
          <Paragraph>
            Our platform may contain links to third-party websites or services. We are not responsible for the privacy practices of those third parties. We encourage you to review the privacy policies of these websites before providing any personal information.
          </Paragraph>

          <SubHeading>9. Children's Privacy</SubHeading>
          <Paragraph>
            Nebulei's Services are not intended for use by individuals under the age of 13. We do not knowingly collect personal data from children under 13. If you believe we have collected such data, please contact us, and we will take appropriate steps to delete it.
          </Paragraph>

          <SubHeading>10. Changes to This Privacy Policy</SubHeading>
          <Paragraph>
            We may update this Privacy Policy from time to time. Any changes will be posted on this page, and the "Effective Date" at the top will be revised. We encourage you to review this Privacy Policy periodically to stay informed about how we are protecting your data.
          </Paragraph>

          <SubHeading>11. Contact Us</SubHeading>
          <Paragraph>
            If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at: <br />
            Email: <Link href="mailto:lalo@nebulei.com">lalo@nebulei.com</Link> <br />
          </Paragraph>
        </Content>
      </Contain>
    </Section>
  )
}

export default Privacy
