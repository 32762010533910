import React from 'react'

import { Section, Contain, Content, Heading, Text, Spacer } from './introStyles'


const Intro = () => {
  return (
    <Section>
      <Contain>
        <Heading>
          Intro
        </Heading>
        <Content>
          <Text>
            Nebulei is more than just a platform—it’s a movement designed to transform the way we engage in academic discourse and problem-solving. 
            We believe that the pursuit of knowledge should be exciting, accessible, and rewarding, not intimidating or overly rigid. At Nebulei, 
            we bring together innovators, researchers, and creators from diverse fields, fostering a space where ideas can be explored, debated, 
            and refined. Our goal is to break down the barriers that often limit creativity and collaboration, encouraging users to dive deep into 
            meaningful challenges.
            <Spacer />
            We’re not just confined to digital spaces; while based in Texas, we’re actively expanding into Europe to immerse ourselves in different 
            knowledge ecosystems, enhancing the diversity and breadth of our community. Whether you’re someone seeking to push the boundaries of 
            human understanding or a professional looking to collaborate on impactful projects, Nebulei is where creativity meets rigor, and where 
            great minds come together to build the future.
          </Text>
        </Content>
      </Contain>
    </Section>
  )
}

export default Intro