import React from 'react'

import SEO from '../../../../common/seo'

import { HeroSection, IntroSection, GoalSection, WhoSection, FutureSection } from './sect/index'


const Goal = () => {
  return (
    <>
    <SEO
      title="About Us | Nebulei"
      description="At Nebulei, we unite diverse professionals to tackle global challenges through collaboration, competition, and optimized tools. Explore how we foster innovation and empower visionary minds to solve the world’s most pressing problems."
      keywords="Nebulei, innovation, collaboration, problem-solving, gamification, global challenges, professional community, technological solutions, visionary minds, progress"
    />
    <HeroSection />
    <IntroSection />
    <GoalSection />
    <WhoSection />
    <FutureSection />
    </>
  )
}

export default Goal