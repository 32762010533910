import React from 'react'

import { Section, Contain, Content, Heading, Text } from './futureStyles'


const Future = () => {
  return (
    <Section>
      <Contain>
        <Heading>
          The Future
        </Heading>
        <Content>
          <Text>
            Looking ahead, Nebulei envisions a future where individuals don’t just collaborate but lead transformative projects that shape industries, 
            academia, and society. As we grow, our platform will become a thriving network of visionaries working to solve tomorrow’s challenges 
            through innovative discoveries, groundbreaking inventions, and philosophical advancements. This future isn’t only about technological progress; 
            it’s about unlocking the full potential of collective intellect, sparking a new era of human achievement. 
            Together, we’ll pave the way for breakthroughs that will redefine how we understand and interact with the world.
          </Text>
        </Content>
      </Contain>
    </Section>
  )
}

export default Future

