import { Link } from 'react-router-dom'
import styled from 'styled-components'


export const Comment = styled.div`
  background-color: ${({ theme }) => theme.backgroundColor.white};
  padding: ${({ theme }) => theme.spacing.sm};
  margin-bottom: ${({ theme }) => theme.spacing.sm};
  border-left: 4px solid
    ${({ $commenterVote, theme }) =>
      $commenterVote === 'commend'
        ? theme.color.vote.commend
        : $commenterVote === 'neutral'
        ? theme.color.vote.neutral
        : $commenterVote === 'oppose'
        ? theme.color.vote.oppose
        : 'transparent'};
`

export const AuthorLink = styled(Link)`
  color: inherit;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.sm};

  &:hover {
    text-decoration: underline;
  }

`


export const AuthorWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing.sm};
  gap: ${({ theme }) => theme.spacing.sm};
  display: flex;
  align-items: center;
`

export const AuthorUser = styled.div`
  color: ${({ theme }) => theme.textColor.primary};
  font-family: ${({ theme }) => theme.font.primary};
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};

  @media (max-width: ${({ theme }) => theme.breakpoint.tablet}) {
    font-size: ${({ theme }) => theme.fontSize.xxs};
  }
`

export const CommentContent = styled.div`
  background-color: ${({ theme }) => theme.backgroundColor.light};
  padding: ${({ theme }) => theme.spacing.md};
`

export const CommentText = styled.div`
  color: ${({ theme }) => theme.textColor.secondary};
  font-family: ${({ theme }) => theme.font.secondary};
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-weight: ${({ theme }) => theme.fontWeight.base};
  user-select: text;
`

export const CommentDate = styled.div`
  padding: ${({ theme }) => theme.spacing.md};
` 

export const CommentAgo = styled.div`
  color: ${({ theme }) => theme.textColor.muted};
  font-family: ${({ theme }) => theme.font.primary};
  font-size: ${({ theme }) => theme.fontSize.xs};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};

  @media (max-width: ${({ theme }) => theme.breakpoint.tablet}) {
    font-size: ${({ theme }) => theme.fontSize.xxs};
  }
`

export const SettingsIconWrapper = styled.div`
  margin-left: auto;
  cursor: pointer;
  position: relative;
`