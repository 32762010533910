import styled from 'styled-components'


export const Section = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 30vh;
  background-color: ${({theme}) => theme.backgroundColor.transparent};
`

export const Contain = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: ${({theme}) => theme.spacing.lg};
  text-align: center;

  @media (max-width: 768px) {
    padding: ${({theme}) => theme.spacing.md};
    max-width: 100%;
  }
`

export const Content = styled.div`
  margin: 0;
  padding: ${({theme}) => theme.spacing.md};
  line-height: 1.5;

  @media (max-width: 768px) {
    padding: ${({theme}) => theme.spacing.sm};
  }
`

export const Header = styled.h1`
  color: ${({theme}) => theme.textColor.primary};
  font-family: ${({theme}) => theme.font.secondary};
  font-weight: ${({theme}) => theme.fontWeight.medium};
  font-size: ${({theme}) => theme.fontSize.xxxl};

  @media (max-width: 768px) {
    font-size: ${({theme}) => theme.fontSize.xxl};
  }
`

export const TypedText = styled.span`
  color: ${({theme}) => theme.textColor.primary};
  font-style: italic;
  display: inline-block;
`

export const EarthText = styled.span`
  color: ${({theme}) => theme.textColor.primary};
  font-weight: ${({theme}) => theme.fontWeight.bold};
  display: inline-block;
  margin-top: ${({theme}) => theme.spacing.sm};

  @media (max-width: 768px) {
    margin-top: ${({theme}) => theme.spacing.xs};
  }
`

export const BtnContain = styled.div`
  margin-top: ${({theme}) => theme.spacing.xl};
`